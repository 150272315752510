import axios from 'axios';
import { getToken, logout, isAuthenticated } from './auth';

const api = axios.create({
  baseURL:
    1 || process.env.NODE_ENV === 'production'
      ? 'https://www.inoveplay.com.br/app'
      : 'http://inoveplay.test/app',
  timeout: 60000
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers['X-Auth'] = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      if (isAuthenticated()) {
        console.log('Unauthorized, logging out ...');
        logout();
        window.location.reload();
      }
    }
    return Promise.reject(error.response);
  }
);

export default api;
