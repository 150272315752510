import React, { useEffect, useReducer, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  BackButton,
  List,
  ListItem,
  ListTitle,
  Page
} from 'react-onsenui';

import MainToolbar from '../../components/MainToolbar';
import api from '../../services/api';
import Links from '../links';

const LeftToolbar = () => {
  const history = useHistory();
  return (
    <BackButton onClick={() => history.push(Links.HOME)}>Voltar</BackButton>
  );
};

const DigitalToolbar = () => (
  <MainToolbar title="Digital" renderLeft={() => <LeftToolbar />} />
);

const digitalInitialState = {
  loading: true,
  isError: false,
  available_amount: '0,00',
  enable_request: false,
  list: []
};

const digitalReducer = (state, action) => ({ ...state, ...action });

const DigitalList = () => {
  const [digital, dispatchDigital] = useReducer(
    digitalReducer,
    digitalInitialState
  );
  const { loading, isError } = digital;

  const loadData = useCallback(() => {
    dispatchDigital({ loading: true });
    api
      .get('user/digital')
      .then(({ data }) => {
        dispatchDigital({ ...data, loading: false, isError: false });
      })
      .catch(() => dispatchDigital({ loading: false, isError: true }));
  }, [dispatchDigital]);

  const doRequest = useCallback(() => {
    dispatchDigital({ loading: true });
    api
      .post('user/digital')
      .then(({ data }) =>
        dispatchDigital({ ...data, loading: false, isError: false })
      )
      .catch(() => dispatchDigital({ loading: false, isError: true }));
  }, [dispatchDigital]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Page renderToolbar={() => <DigitalToolbar />}>
      {!!loading && <ListTitle>Carregando...</ListTitle>}
      {!loading && isError && (
        <>
          <ListTitle>Falha ao carregar</ListTitle>
          <List>
            <Button modifier="large--quiet" onClick={() => loadData()}>
              Recarregar
            </Button>
          </List>
        </>
      )}
      {!loading &&
        !isError &&
        (!digital?.list?.length ? (
          <ListTitle>Não há relatórios.</ListTitle>
        ) : (
          <>
            <ListTitle>&nbsp;</ListTitle>
            <List>
              {digital.list.map((item) => (
                <ListItem
                  key={`digital${item.id}`}
                  modifier="chevron"
                  tappable
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(item.file);
                  }}
                >
                  <div className="left">{item.name}</div>
                  <div className="right">
                    {item.status === 'complete' && (
                      <span className="badge bg-success">
                        <i className="fas fa-check" /> Sacado
                      </span>
                    )}
                    {item.status === 'requested' && (
                      <span className="badge bg-warning">
                        <i className="fas fa-sync" /> Processando
                      </span>
                    )}
                    {item.status === 'available' && (
                      <span className="badge bg-success">R$ {item.amount}</span>
                    )}
                  </div>
                </ListItem>
              ))}
              {digital.enable_request && (
                <>
                  <ListItem>
                    <div className="left">Total Disponível</div>
                    <div className="right">
                      <div className="list-item__label">
                        R$ {digital.available_amount}
                      </div>
                    </div>
                  </ListItem>
                </>
              )}
            </List>
            {digital.enable_request && (
              <>
                <ListTitle>&nbsp;</ListTitle>
                <List>
                  <Button modifier="large--quiet" onClick={doRequest}>
                    Solicitar Saque
                  </Button>
                </List>
              </>
            )}
          </>
        ))}
    </Page>
  );
};

export default DigitalList;
