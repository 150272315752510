import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  //Button,
  BackButton,
  BottomToolbar,
  Icon,
  Input,
  List,
  ListItem,
  ListTitle,
  Modal,
  Page,
  ProgressCircular,
  Select,
  ToolbarButton,
} from 'react-onsenui';
import ons from 'onsenui';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

import MainToolbar from '../../components/MainToolbar';
import api from '../../services/api';
import Links from '../../pages/links';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  state = {
    loading: false,
    data: {
      old_password: "",
      new_password: "",
      new_password2: "",
    },
    changed: false,
  };

  async saveProfile() {
    if (!this.state.changed) {
      return;
    }
    try {
      const { old_password, new_password } = this.state.data;

      this.setState({ loading: "Salvando..." });
      await api.put('/user/change-password', { old_password, new_password });

      this.setState({
        loading: false, changed: false, data: {
          old_password: "",
          new_password: "",
          new_password2: "",
        }
      });

      ons.notification.alert('Senha alterada com sucesso.', { title: '' });
    } catch (err) {
      ons.notification.confirm('Não foi possível salvar. ' + (err && err.data && err.data.message), {
        title: 'Erro',
        buttonLabels: ["Tentar de novo", "Cancelar"],
      }).then(data => {
        if (0 === data) {
          this.saveProfile();
        }
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  renderBackButton() {
    return (
      <BackButton
        onClick={() => { this.props.history.push(Links.HOME) }}
      >Voltar</BackButton>
    );
  }

  renderButtonSave() {
    return (
      <ToolbarButton
        modifier="outline"
        disabled={!this.state.changed
          || !this.state.data.new_password
          || this.state.data.new_password.length < 8
          || this.state.data.new_password !== this.state.data.new_password2
          || this.state.loading ? true : false}
        onClick={() => { this.saveProfile() }}
      >
        Salvar
      </ToolbarButton>
    );
  }

  renderToolbar() {
    return (
      <>
        <MainToolbar
          title="Alterar Senha"
          renderLeft={() => this.renderBackButton()}
          renderRight={() => this.renderButtonSave()}
        />
        <BottomToolbar modifier="material aligned" className="right">
          <div>
            {this.renderButtonSave()}
          </div>
        </BottomToolbar>
      </>
    );
  }

  setInputData(e) {
    let { name, value } = e.target;

    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
      changed: true,
    })
  }

  render() {
    const { data } = this.state;

    const authorFields = [
      {
        name: 'Trocar Senha',
        items: [
          { name: 'old_password', label: 'Senha antiga', maxLength: 50, password: true, right: null },
          {
            name: 'new_password', label: 'Nova senha', maxLength: 50, password: true,
            right: (
              data.new_password && data.new_password.length >= 8
                ? <Icon icon="check" className="text-success" />
                : this.state.changed
                  ? <Icon icon="times" className="text-danger" />
                  : null
            )
          },
          {
            name: 'new_password2', label: 'Repita a nova senha', maxLength: 50, password: true,
            right: (
              data.new_password && data.new_password === data.new_password2
                ? <Icon icon="check" className="text-success" />
                : this.state.changed
                  ? <Icon icon="times" className="text-danger" />
                  : null
            )
          },
        ]
      },
    ];

    return (
      <Page renderToolbar={() => this.renderToolbar()}>
        <ListTitle>Instruções</ListTitle>
        <List>
          <ListItem>
            <div>
              <p>
                Para alterar a sua senha:
            </p>
              <ul>
                <li>Preencha a sua senha atual;</li>
                <li>Informe uma nova senha com mínimo <b>8</b> letras e/ou números e/ou símbolos;</li>
                <li>Repita a nova senha.</li>
              </ul>
            </div>
          </ListItem>
        </List>
        {authorFields.map((group, index) => (
          <div key={index}>
            <ListTitle>{group.name}</ListTitle>
            <List>
              {group.items.map((field, fieldKey) => (
                <ListItem key={fieldKey}>
                  <div className="left form-label">
                    <label>{field.label}</label>
                  </div>
                  <div className="center">

                    {(field.custom && field.custom(field)) ||
                      (field.select
                        ? <Select
                          name={field.name}
                          style={{ width: '100%' }}
                          value={data[field.name]}
                          onChange={(e) => this.setInputData(e)}
                          placeholder={field.placeholder || field.label}
                        >
                          {field.select.map((i, key) => (<option hidden={i.key === ""} key={key} value={i.key}>{i.label}</option>))}
                        </Select>
                        : <Input
                          style={{ width: '100%' }}
                          type={field.password ? 'password' : 'text'}
                          maxLength={field.maxLength || 100}
                          float
                          disabled={field.disabled || false}
                          name={field.name}
                          value={(field.mask && field.mask(data[field.name])) || data[field.name]}
                          onChange={(e) => this.setInputData(e)}
                          placeholder={field.placeholder || field.label}
                        />)}
                  </div>
                  <div className="right">
                    {field.right}
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        ))}

        <div style={{ height: 80 }}></div>

        <Modal isOpen={this.state.loading ? true : false}>
          <ProgressCircular indeterminate />
          <div>{this.state.loading}</div>
        </Modal>
      </Page >
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
