import styled from 'styled-components';
import * as Ons from 'react-onsenui';

export const Section = styled.section`
  text-align: center;
  margin: 16px;
`;

export const Card = styled(Ons.Card)`
  text-align: center;
`;

export const Button = styled(Ons.Button)`
  min-width: 200px;
`;
