import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Icon,
  Toolbar,
  ToolbarButton,
} from 'react-onsenui';

import { Creators as LayoutActions } from '../../store/ducks/layout';
// import { Container } from './styles';

const MainToolbar = ({ title, layout, layoutActions, renderRight, renderLeft }) => (
  <Toolbar>
    <div className="left">
      {(renderLeft && renderLeft()) || (
        layout.isMobile ? (
          <ToolbarButton onClick={() => layoutActions.toggleMenu(true)}>
            <Icon icon="bars" />
          </ToolbarButton>
        ) : null
      )}
    </div>
    <div className="center">{title}</div>
    {renderRight ? (
      <div className="right">
        {renderRight()}
      </div>
    ) : null}
  </Toolbar>
);

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  layoutActions: bindActionCreators(LayoutActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainToolbar);
