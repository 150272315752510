import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Checkbox,
  Button,
  Input,
  Modal,
  Page,
  ProgressCircular
} from 'react-onsenui';
import ons from 'onsenui';
import { Creators as UserActions } from '../../store/ducks/user';
// import { Creators as LayoutActions } from '../../store/ducks/layout';
import api from '../../services/api';
import { login } from '../../services/auth';
// , isAuthenticated

// import { Form, Container } from "./styles";

import logomarca512 from '../../assets/logomarca512.png';

class SignUp extends Component {
  state = {
    trying: false,
    username: '',
    email: '',
    pwd: '',
    accept: false,
    error: ''
  };

  showError(error) {
    ons.notification.alert(error, { title: 'Erro' });
    this.setState({ error });
  }

  handleSignUp = async (e) => {
    e.preventDefault();
    const { email, pwd, accept } = this.state;

    if (!email || !pwd) {
      this.showError('Preencha todos os dados para se cadastrar');
      return;
    }

    if (!accept) {
      this.showError(
        'É necessário aceitar os termos de uso e política de privacidade para se cadastrar'
      );
      return;
    }

    this.setState({
      trying: true
    });

    try {
      const response = await api.post('/users', { email, pwd });

      if (response.data.token) {
        const { token } = response.data;

        login(token);
        this.props.userActions.checkIsAuthenticated();
        this.props.history.push('/app');
        return; // must return after a push, because it will unmount the component
      }
      this.showError('Erro ao criar conta. Tente novamente.');
      this.setState({ trying: false });
    } catch (err) {
      this.showError(
        (err.response && err.response.data && err.response.data.message) ||
          'Ocorreu um erro ao criar a conta.'
      );
      this.setState({ trying: false });
    }
  };

  render() {
    return (
      <Page>
        <form className="Login" onSubmit={this.handleSignUp}>
          <div>
            <img src={logomarca512} alt="Inoveplay" width="192" height="192" />
          </div>
          <div style={{ marginBottom: 8 }}>Criar conta grátis</div>

          {this.state.error && <p>{this.state.error}</p>}

          <Input
            disabled={this.state.trying}
            modifier="underbar"
            type="email"
            float
            autocomplete="new-password"
            placeholder="E-mail"
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          <br />
          <Input
            disabled={this.state.trying}
            modifier="underbar"
            type="password"
            float
            autocomplete="new-password"
            placeholder="Nova senha"
            onChange={(e) => this.setState({ pwd: e.target.value })}
          />

          <br />
          <div style={{ textAlign: 'left', display: 'flex' }}>
            <Checkbox
              disabled={this.state.trying}
              onChange={(e) => this.setState({ accept: !!e.target.checked })}
            />
            <div style={{ marginLeft: 8 }}>
              Marque se você concorda com os nossos{' '}
              <a
                href="https://www.inoveplay.com.br/termos-de-uso"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>termos de uso</u>
              </a>{' '}
              e{' '}
              <a
                href="https://www.inoveplay.com.br/politica-de-privacidade"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>política de privacidade</u>
              </a>
              .
            </div>
          </div>
          <br />
          <Button
            disabled={this.state.trying}
            onClick={(e) => this.handleSignUp(e)}
          >
            Cadastrar grátis
          </Button>
          <br />
          <Link to="/">Fazer login</Link>
        </form>
        <Modal isOpen={!!this.state.trying}>
          <ProgressCircular indeterminate />
          <div>Criando conta...</div>
        </Modal>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
