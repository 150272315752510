import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as UserActions } from '../../store/ducks/user';
//import { Creators as LayoutActions } from '../../store/ducks/layout';
import api from "../../services/api";
import { login } from "../../services/auth";
//, isAuthenticated

import queryString from 'query-string';

//import { Form, Container } from "./styles";
import {
  Button,
  Input,
  Modal,
  Page,
  ProgressCircular,
} from "react-onsenui";
import ons from 'onsenui';


import logomarca512 from '../../assets/logomarca512.png';


class RecoverNewPassword extends Component {
  state = {
    trying: false,
    token: "",
    email: "",
    pwd: "",
    accept: false,
    error: "",
  };

  componentDidMount() {
    try {
      const { hash } = queryString.parse(this.props.location.search);
      if (!hash) {
        this.props.history.push("/recover");
        return;
      }

      const json = atob(hash);
      const { e, t } = JSON.parse(json);
      this.setState({
        email: e,
        token: t,
      });

      return;
    } catch (e) {
      this.props.history.push("/recover");
    }
  }

  showError(error) {
    ons.notification.alert(error, { title: 'Erro' });
    this.setState({ error });
  }

  handleRecoverNewPassword = async e => {
    e.preventDefault();
    const { email, pwd, token } = this.state;

    if (!pwd || pwd.length < 6) {
      this.showError("Preencha a nova senha com pelo menos 6 caracteres");
      return;
    }

    this.setState({
      trying: true,
    });

    try {
      const response = await api.post("/user/login", { email, pwd, token });

      if (response.data.token) {
        const { token } = response.data;

        login(token);
        this.props.userActions.checkIsAuthenticated();
        this.props.history.push("/app");
        return;
      } else {
        this.props.history.push("/recover");
        return;
      }
    } catch (err) {
      this.props.history.push("/recover");
      return;
    }
  };

  render() {
    return (
      <Page>
        <form className="Login" onSubmit={this.handleRecoverNewPassword}>
          <div>
            <img src={logomarca512} alt="Inoveplay" width="192" height="192" />
          </div>
          <div style={{ marginBottom: 8 }}>
            Criar nova senha
          </div>

          {this.state.error && <p>{this.state.error}</p>}


          <Input
            disabled={this.state.trying}
            modifier='underbar'
            type="password"
            float
            autocomplete="new-password"
            placeholder="Nova senha"
            onChange={e => this.setState({ pwd: e.target.value })}
          />

          <br />
          <Button
            disabled={this.state.trying}
            onClick={e => this.handleRecoverNewPassword(e)}
          >Salvar</Button>
          <br />
          <Link to="/">Fazer login</Link>

        </form>
        <Modal isOpen={this.state.trying ? true : false}>
          <ProgressCircular indeterminate />
          <div>Atualizando...</div>
        </Modal>
      </Page >
    );
  }
}

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoverNewPassword));
