import SongList from "../../components/SongList";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

class GuideList extends SongList { };

const mapStateToProps = state => ({
  user: state.user,
  layout: state.layout,
  title: 'Guias Solicitadas',
  endpoint: '/user/guide-list',
  addButton: true,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuideList);