import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as UserActions } from '../../store/ducks/user';

import api from "../../services/api";

import {
  Button,
  Input,
  Modal,
  Page,
  ProgressCircular,
} from "react-onsenui";
import ons from 'onsenui';


import logomarca512 from '../../assets/logomarca512.png';


class Recover extends Component {
  state = {
    trying: false,
    username: "",
    email: "",
    error: "",
  };

  showError(error) {
    ons.notification.alert(error, { title: 'Erro' });
    this.setState({ error });
  }

  handleSignUp = async e => {
    e.preventDefault();
    const { email } = this.state;

    if (!email) {
      this.showError("Preencha seu e-mail");
      return;
    }

    this.setState({
      trying: true,
    });

    try {
      const response = await api.post("/user/recover", { email });

      if (response.data && response.data.ok === 1) {
        ons.notification.alert('Instruções de recuperação de acesso à sua conta foram enviadas para o seu e-mail.', { title: '' }).then(() => this.props.history.push("/"));
        return;
      } else {
        this.showError("Ocorreu um erro ao processar a solicitação.");
        this.setState({ trying: false });
      }
    } catch (err) {
      this.showError((err.response && err.response.data && err.response.data.message) || "Ocorreu um erro ao processar a solicitação.");
      this.setState({ trying: false });
    }
  };

  render() {
    return (
      <Page>
        <form className="Login" onSubmit={this.handleSignUp}>
          <div>
            <img src={logomarca512} alt="Inoveplay" width="192" height="192" />
          </div>

          <div style={{ marginBottom: 8 }}>
            Recuperar acesso à conta
          </div>

          {this.state.error && <p>{this.state.error}</p>}

          <Input
            disabled={this.state.trying}
            modifier='underbar'
            type="email"
            float
            autocomplete="new-password"
            placeholder="E-mail"
            onChange={e => this.setState({ email: e.target.value })}
          />
          <br />
          <br />
          <Button
            disabled={this.state.trying}
            onClick={e => this.handleSignUp(e)}
          >Recuperar</Button>
          <br />
          <Link to="/">Fazer login</Link>

        </form>
        <Modal isOpen={this.state.trying ? true : false}>
          <ProgressCircular indeterminate />
          <div>Recuperando conta...</div>
        </Modal>
      </Page >
    );
  }
}

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Recover));
