import React, { Component } from 'react';

import {
  ActionSheet,
  ActionSheetButton,
  Button,
  BackButton,
  // BottomToolbar,
  Icon,
  // Input,
  List,
  ListItem,
  // ListTitle,
  // Modal,
  Page,
  ProgressCircular,
  ToolbarButton,
  Switch
  // SpeedDial,
  // SpeedDialItem,
  // Fab,
} from 'react-onsenui';
import ons from 'onsenui';

import MainToolbar from '../MainToolbar';
import api from '../../services/api';
import Links from '../../pages/links';
import { songButtons } from '../songs';

class SongList extends Component {
  state = {
    loadingMoreSongs: false,
    page: 0,
    limit: 30,
    hasMore: true,
    items: [],
    openSongMenu: 0,
    currentSong: {}
  };

  async componentDidMount() {
    this.loadMoreSongs();
  }

  async loadMoreSongs() {
    if (!this.state.hasMore || this.state.loadingMoreSongs) {
      return;
    }
    const limit = this.props.switchPublished ? 1000 : this.state.limit;
    try {
      this.setState({ loadingMoreSongs: true });

      const { data } = await api.get(this.props.endpoint, {
        params: {
          page: this.state.page + 1,
          limit
        }
      });
      const { page, hasMore, items } = data;
      this.setState({
        page,
        hasMore,
        items: this.state.items.concat(items),
        loadingMoreSongs: false
      });
    } catch (err) {
      ons.notification
        .confirm('Não foi possível carregar os dados.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Cancelar']
        })
        .then((data) => {
          if (data === 0) {
            this.loadMoreSongs();
          }
        });
    } finally {
      this.setState({
        loadingMoreSongs: false
      });
    }
  }

  async resetSongList() {
    await this.setState({
      page: 0,
      hasMore: true,
      loadingMoreSongs: false,
      openSongMenu: 0,
      currentSong: {},
      items: []
    });

    this.loadMoreSongs();
  }

  editSong(songId, song) {
    /*
    if (!song.is_filled) {
      this.goToEditSong(songId);
      return;
    }
    */

    this.setState({
      openSongMenu: songId,
      currentSong: song
    });
  }

  goToEditSong(songId) {
    this.props.history.push(Links.SONG_EDIT.replace(':songId', songId));
  }

  addSong() {
    this.props.history.push(Links.SONG_ADD);
  }

  solicitaEdicaoSong(songId) {
    this.props.history.push(Links.EDITION_REQUEST.replace(':songId', songId));
  }

  solicitaGuiaSong(songId) {
    this.props.history.push(Links.GUIDE_REQUEST.replace(':songId', songId));
  }

  removeDraftConfirm(songId) {
    ons.notification
      .confirm('Deseja mesmo remover este rascunho?', {
        title: '',
        buttonLabels: ['Sim, remover', 'Não']
      })
      .then((data) => {
        if (data === 0) {
          this.removeDraft(songId);
        }
      });
  }

  async removeDraft(songId) {
    try {
      await api.delete(`/user/songs/${songId}`);

      this.resetSongList();
    } catch (err) {
      ons.notification
        .confirm('Não foi possível remover.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Cancelar']
        })
        .then((data) => {
          if (data === 0) {
            this.removeDraft(songId);
          } else {
            this.resetSongList();
          }
        });
    }
  }

  async handleSwitchSongComposerPublished(event, songId, published, index) {
    event.stopPropagation();

    const { items } = this.state;
    try {
      const response = await api.put(`user/songs/${songId}/published`, {
        published
      });

      items[index] = response.data;
      this.setState({ items });
    } catch (e) {}
  }

  editionPayClick(song) {
    this.setState({ openSongMenu: 0 });

    ons.notification
      .confirm(
        `Pagar Contrato de Edição da composição <strong>${song.name}</strong>`,
        {
          title: 'Confirmação',
          buttonLabels: ['Pagar', 'Alterar Plano', 'Depois']
        }
      )
      .then(async (data) => {
        if (data === 0) {
          try {
            const songr = await api.post(`/user/songs/${song.id}/pagar-edicao`);

            if (songr.data.contrato_edicao_payment_url) {
              const wo = window.open(
                songr.data.contrato_edicao_payment_url,
                '_blank'
              );
              if (!wo) {
                const messageHTML = `<a
                  href="${songr.data.contrato_edicao_payment_url}"
                  rel="noopener noreferrer"
                  target="_blank"
                >Clique aqui</a> para abrir o boleto.`;
                ons.notification
                  .alert({
                    title: 'Aviso',
                    messageHTML,
                    buttonLabels: ['Fechar']
                  })
                  .then(() => {});
              }
            } else {
              ons.notification.alert('Falha ao gerar boleto.');
            }

            if (
              song.contrato_edicao_payment_url !==
                songr.data.contrato_edicao_payment_url ||
              songr.data.contrato_edicao_payment_paid === '1'
            ) {
              this.resetSongList();
            }
          } catch (e) {
            ons.notification.alert('Falha ao gerar boleto.', {
              title: 'Aviso'
            });
          }
        } else if (data === 1) {
          this.props.history.push(Links.PLAN);
        }
      });

    // this.props.history.push(Links.EDITION_SIGNED_LIST.replace(':songId', song.id));
  }

  guidePayClick(song) {
    this.setState({ openSongMenu: 0 });

    ons.notification
      .confirm(`Pagar Guia da composição <strong>${song.name}</strong>`, {
        title: 'Confirmação',
        buttonLabels: ['Pagar', 'Depois']
      })
      .then(async (data) => {
        if (data === 0) {
          try {
            const songr = await api.post(`/user/songs/${song.id}/pagar-guia`);

            if (songr.data.guia_payment_url) {
              const wo = window.open(songr.data.guia_payment_url, '_blank');
              if (!wo) {
                const messageHTML = `<a
                  href="${songr.data.guia_payment_url}"
                  rel="noopener noreferrer"
                  target="_blank"
                >Clique aqui</a> para abrir o boleto.`;
                ons.notification
                  .alert({
                    title: 'Aviso',
                    messageHTML,
                    buttonLabels: ['Fechar']
                  })
                  .then(() => {});
              }
            } else {
              ons.notification.alert('Falha ao gerar boleto.');
            }
            if (
              song.guia_payment_url !== songr.data.guia_payment_url ||
              songr.data.guia_payment_paid === '1'
            ) {
              this.resetSongList();
            }
          } catch (e) {
            ons.notification.alert('Falha ao gerar boleto.');
          }
        }
      });
    // this.props.history.push(Links.GUIDE_LIST.replace(':songId', song.id));
  }

  renderBackButton() {
    return (
      <BackButton
        onClick={() => {
          this.props.history.push(Links.HOME);
        }}
      >
        Voltar
      </BackButton>
    );
  }

  renderToolbarRight() {
    return (
      (this.props.addButton && (
        <ToolbarButton modifier="outline" onClick={() => this.addSong()}>
          <Icon icon="plus" />
        </ToolbarButton>
      )) ||
      null
    );
  }

  renderToolbar() {
    return (
      <>
        <MainToolbar
          title={this.props.title}
          renderLeft={() => this.renderBackButton()}
          renderRight={() => this.renderToolbarRight()}
        />
      </>
    );
  }

  renderModal() {
    const { currentSong } = this.state;

    const {
      removeDraft,
      editionRequest,
      editionSign,
      editionPay,
      editionContractDownload,
      guideRequest,
      guidePay,
      isPublished
    } = songButtons(currentSong, this.props.user.data);

    return (
      <>
        {this.state.openSongMenu ? (
          <ActionSheet
            isOpen={!!this.state.openSongMenu}
            onCancel={() => this.setState({ openSongMenu: 0 })}
          >
            <div className="action-sheet-title">{currentSong.name}</div>

            {isPublished && (
              <ActionSheetButton
                onClick={() => window.open(currentSong.short_url)}
              >
                Abrir Música na Plataforma
              </ActionSheetButton>
            )}

            <ActionSheetButton
              onClick={() => this.goToEditSong(currentSong.id)}
            >
              {!currentSong.data_readonly &&
              currentSong.account_id === this.props.user.data.id
                ? 'Alterar Informações'
                : 'Ver Informações'}
            </ActionSheetButton>

            {editionRequest && (
              <ActionSheetButton
                onClick={() => this.solicitaEdicaoSong(currentSong.id)}
              >
                Solicitar Edição
              </ActionSheetButton>
            )}

            {editionSign && (
              <ActionSheetButton
                onClick={() =>
                  this.props.history.push(
                    Links.EDITION_SIGN.replace(':songId', currentSong.id)
                  )
                }
              >
                Assinar Contrato de Edição
              </ActionSheetButton>
            )}

            {editionPay && (
              <ActionSheetButton
                onClick={() => this.editionPayClick(currentSong)}
              >
                Pagar Edição ou Alterar Plano
              </ActionSheetButton>
            )}

            {editionContractDownload && (
              <ActionSheetButton
                onClick={() =>
                  window.open(currentSong.contrato_edicao_file_url)
                }
              >
                Acessar Contrato de Edição
              </ActionSheetButton>
            )}

            {guideRequest && (
              <ActionSheetButton
                onClick={() => this.solicitaGuiaSong(currentSong.id)}
              >
                Solicitar Guia
              </ActionSheetButton>
            )}

            {guidePay && (
              <ActionSheetButton
                onClick={() => this.guidePayClick(currentSong)}
              >
                Pagar Guia
              </ActionSheetButton>
            )}

            {removeDraft && (
              <ActionSheetButton
                onClick={() => this.removeDraftConfirm(currentSong.id)}
              >
                Excluir Rascunho
              </ActionSheetButton>
            )}

            <ActionSheetButton
              onClick={() => this.setState({ openSongMenu: 0 })}
            >
              Cancelar
            </ActionSheetButton>
          </ActionSheet>
        ) : null}
      </>
    );
  }

  handleReorderUp(e, index) {
    e.stopPropagation();
    const { items } = this.state;
    if (index <= 0) {
      return;
    }
    const i0 = items[index - 1];
    const i1 = items[index];
    if (!i0 || !i1) {
      return;
    }
    items[index - 1] = i1;
    items[index] = i0;
    this.setState({ items });

    const list = [];
    items.forEach((item) => {
      list.push(item.id);
    });
    api.post('user/songs/order', { list });
  }

  handleReorderDown(e, index) {
    e.stopPropagation();
    const { items } = this.state;
    if (index < 0 || index >= items.length - 1) {
      return;
    }
    const i0 = items[index];
    const i1 = items[index + 1];
    if (!i0 || !i1) {
      return;
    }
    items[index] = i1;
    items[index + 1] = i0;
    this.setState({ items });

    const list = [];
    items.forEach((item) => {
      list.push(item.id);
    });
    api.post('user/songs/order', { list });
  }

  render() {
    const { items, hasMore } = this.state;

    /// <ListTitle>Informações da Música</ListTitle>
    return (
      <Page
        renderToolbar={() => this.renderToolbar()}
        renderModal={() => this.renderModal()}
        onInfiniteScroll={() => this.loadMoreSongs()}
        // renderFixed={() => <SpeedDial position='right bottom'><Fab onClick={() => this.addSong()}><Icon icon='plus' /></Fab></SpeedDial>}
      >
        {items.length === 0 && !this.state.loadingMoreSongs && (
          <p style={{ textAlign: 'center' }}>
            {this.props.emptyListMessage
              ? this.props.emptyListMessage
              : 'Lista vazia.'}
          </p>
        )}
        <List>
          {items.map((item, index) => {
            const {
              isEdited,
              isPublished,
              // editionRequest,
              editionSign,
              editionPay,
              // guideRequest,
              guidePay
            } = songButtons(item, this.props.user.data);

            return !item.id ? null : (
              <ListItem
                key={item.id}
                modifier="tappable"
                onClick={() => this.editSong(item.id, item)}
              >
                <div className="left">
                  <span className="badge">{index + 1}</span>
                </div>
                <div className="center">
                  <span style={{ marginRight: 8 }}>{item.name}</span>

                  {item.composerList &&
                    item.composerList.length &&
                    item.composerList.map((author, index) => (
                      <img
                        key={index}
                        style={{
                          width: 16,
                          height: 16,
                          borderRadius: 8,
                          marginRight: 4
                        }}
                        src={author.img_url_mini}
                        alt={author.nickname}
                        title={author.nickname}
                      />
                    ))}
                </div>
                <div className="right">
                  {isPublished ? (
                    this.props.switchPublished ? (
                      <>
                        <Switch
                          checked={item.profile_published === '1'}
                          onClick={(e) =>
                            this.handleSwitchSongComposerPublished(
                              e,
                              item.id,
                              item.profile_published === '1' ? '0' : '1',
                              index
                            )
                          }
                        />
                        <span>&nbsp;</span>
                        <Button
                          modifier="outline small"
                          onClick={(e) => this.handleReorderUp(e, index)}
                        >
                          <Icon icon="chevron-up" />
                        </Button>
                        <span>&nbsp;</span>
                        <Button
                          modifier="outline"
                          onClick={(e) => this.handleReorderDown(e, index)}
                        >
                          <Icon icon="chevron-down" />
                        </Button>
                      </>
                    ) : (
                      <span className="badge bg-success">Publicada</span>
                    )
                  ) : isEdited ? (
                    <span className="badge bg-success">Editada</span>
                  ) : editionSign ? (
                    <span className="badge bg-danger">Assinar</span>
                  ) : editionPay ? (
                    <span className="badge bg-danger">Pagar Edição</span>
                  ) : item.is_filled && item.solicita_edicao === '1' ? (
                    <span className="badge bg-warning">Editando</span>
                  ) : !item.is_filled || item.solicita_guia !== '1' ? (
                    <span className="badge bg-light">Rascunho</span>
                  ) : (
                    <></>
                  )}

                  {item.is_filled &&
                    item.solicita_guia === '1' &&
                    (guidePay ? (
                      <span className="badge bg-danger">Guia</span>
                    ) : (
                      <span className="badge bg-success">Guia OK</span>
                    ))}
                </div>
              </ListItem>
            );
          })}
        </List>
        {!this.state.loadingMoreSongs && hasMore && (
          <div style={{ margin: 10 }}>
            <Button
              modifier="large outline"
              onClick={() => this.loadMoreSongs()}
            >
              Carregar mais
            </Button>
          </div>
        )}

        {this.state.loadingMoreSongs && (
          <div style={{ textAlign: 'center', margin: 8 }}>
            <ProgressCircular indeterminate />
          </div>
        )}
      </Page>
    );
  }
}

export default SongList;
