import React, { Component } from 'react';

import {
  Button,
  BackButton,
  // BottomToolbar,
  Icon,
  Input,
  List,
  ListItem,
  ListTitle,
  Modal,
  Page,
  ProgressCircular
  // ProgressBar,
  // ToolbarButton,
} from 'react-onsenui';
import ons from 'onsenui';

import ReactAudioPlayer from 'react-audio-player';
import * as yup from 'yup';

import MainToolbar from '../MainToolbar';
import api from '../../services/api';
import Links from '../../pages/links';
import { songButtons } from '../songs';
import { FileInput } from './style';

const isEmailValid = (email) =>
  yup.string().email().required().isValidSync(email);

class SongForm extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  state = {
    uploadProgress: 0,
    loading: false,
    data: {
      id: '',
      name: '',
      lyrics: '',
      account_id: '',
      is_filled: false,
      coauthors: [{ nickname: '', email: '' }],
      audio_file_url: ''
    },
    readonly: false,
    changed: false,
    file: null,
    file64: null
  };

  async componentDidMount() {
    const { songId } = this.props.match.params;

    this.loadSong(songId);
  }

  async loadSong(id) {
    if (!id) {
      this.setState({
        data: {
          id: '',
          name: '',
          lyrics: '',
          coauthors: [{ nickname: '', email: '' }],
          audio_file_url: ''
        },
        changed: false,
        loading: false,
        readonly: false
      });
      return;
    }

    try {
      this.setState({ loading: 'Carregando...' });

      const { data } = await api.get(`/user/songs/${id}`);
      this.setState({
        data,
        changed: false,
        loading: false,
        readonly: !!data.data_readonly
        // file: null,
        // file64: null,
      });
    } catch (err) {
      if (err && err.status === 404) {
        this.props.history.push(Links.SONGS);
        return;
      }
      this.setState({ loading: false });
      ons.notification
        .confirm('Não foi possível carregar os dados.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Sair']
        })
        .then((data) => {
          if (data === 0) {
            this.loadSong();
          } else if (data === 1) {
            this.props.history.push(Links.HOME);
          }
        });
    }
  }

  async save() {
    if (!this.state.changed || this.state.readonly) {
      return;
    }

    this.setState({ loading: 'Salvando...' });

    try {
      const { id, name, lyrics, coauthors } = this.state.data;
      let response;

      if (id) {
        if (this.state.data.solicita_edicao === '1') {
          // TODO: salva se mostra ou não no próprio perfil
        } else {
          response = await api.put(`/user/songs/${id}`, {
            name,
            lyrics,
            coauthors
          });
        }
      } else {
        response = await api.post('/user/songs/', {
          name,
          lyrics,
          coauthors
        });
      }

      if (response && response.data && response.data.id) {
        let { data } = response;

        if (this.state.file64) {
          const formData = new FormData();
          const { file } = this.state;

          formData.append('attachment', file, file.name);
          const fileResponse = await api.post(
            `/user/songs/${data.id}/audio`,
            formData,
            {
              headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
              },
              timeout: 600000,
              onUploadProgress: (ev: ProgressEvent) => {
                const uploadProgress = Math.round((ev.loaded / ev.total) * 95);
                this.setState({ uploadProgress });
              }
            }
          );

          data = fileResponse.data;
        }

        if (!id) {
          if (data.name && data.lyrics && data.audio_file_url) {
            this.props.history.push(
              Links.EDITION_REQUEST.replace(':songId', data.id)
            );
          } else {
            this.props.history.push(
              Links.SONG_EDIT.replace(':songId', data.id)
            );
          }
          return;
        }

        this.setState({
          uploadProgress: 0,
          data,
          changed: false,
          file: null,
          file64: null,
          loading: false
        });
      } else {
        ons.notification
          .confirm('Não foi possível salvar.', {
            title: 'Erro',
            buttonLabels: ['Tentar de novo', 'Cancelar']
          })
          .then((data) => {
            if (data === 0) {
              this.save();
            }
          });
        this.setState({
          uploadProgress: 0,
          loading: false
        });
      }
    } catch (err) {
      ons.notification
        .confirm(
          `Não foi possível salvar. ${
            (err && err.data && err.data.message) || ''
          }`,
          {
            title: 'Erro',
            buttonLabels: ['Tentar de novo', 'Cancelar']
          }
        )
        .then((data) => {
          if (data === 0) {
            this.save();
          }
        });

      this.setState({
        uploadProgress: 0,
        loading: false
      });
    }
  }

  loadImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ file64: e.target.result });
    };
    reader.readAsDataURL(file);
  }

  renderBackButton() {
    return (
      <BackButton
        onClick={() => {
          this.props.history.push(Links.SONGS);
        }}
      >
        Voltar
      </BackButton>
    );
  }

  renderButtonSave() {
    /*
    return (
      <ToolbarButton
        modifier="outline"
        disabled={!this.state.changed || this.state.readonly || this.state.loading || !this.state.data.name || !this.state.data.lyrics ? true : false}
        onClick={() => { this.save(); }}
      >
        Salvar
      </ToolbarButton>
    );
    */
    return null;
  }

  renderButtonSaveLarge() {
    return (
      <Button
        modifier="large"
        disabled={
          !!(
            !this.state.changed ||
            this.state.readonly ||
            this.state.loading ||
            !this.state.data.name ||
            !this.state.data.lyrics
          )
        }
        onClick={() => {
          this.save();
        }}
      >
        Salvar
      </Button>
    );
  }

  getAuthorEmailList() {
    const text = localStorage.getItem('aeList');
    return text ? JSON.parse(text) : {};
  }

  setAuthorEmailList(list) {
    localStorage.setItem('aeList', JSON.stringify(list));
  }

  simpleNickname(nickname) {
    return (nickname || '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^A-Za-z0-9]/g, ' ')
      .toLowerCase()
      .replace(/ +/, ' ')
      .trim();
  }

  getDefaultEmail(nickname) {
    const key = this.simpleNickname(nickname);
    const list = this.getAuthorEmailList();
    return (key && list[key]) || '';
  }

  setDefaultEmail(nickname, email) {
    const key = this.simpleNickname(nickname);
    const list = this.getAuthorEmailList();
    if (email) {
      list[key] = email;
    } else {
      delete list[key];
    }
    this.setAuthorEmailList(list);
  }

  editAuthor(nickname, index) {
    if (this.state.readonly) {
      return;
    }
    const { coauthors } = this.state.data;
    const { data } = this.state;
    coauthors[index].nickname = nickname;
    if (!coauthors[index].disableAutoFill || nickname.length <= 1) {
      coauthors[index].email = this.getDefaultEmail(nickname);
      coauthors[index].disableAutoFill = false;
    }

    const newcoauthors = coauthors.filter(function (el) {
      return el.nickname.trim() !== '';
    });
    newcoauthors.push({ nickname: '', email: '' });

    this.setState({
      data: {
        ...data,
        coauthors: newcoauthors
      },
      changed: true
    });
  }

  editEmail(email, index) {
    if (this.state.readonly) {
      return;
    }
    const { coauthors } = this.state.data;
    const { data } = this.state;
    coauthors[index].email = email;
    coauthors[index].disableAutoFill = true;
    email &&
      isEmailValid(email) &&
      this.setDefaultEmail(coauthors[index].nickname, email);

    this.setState({
      data: {
        ...data,
        coauthors
      },
      changed: true
    });
  }

  setData(key, value) {
    if (this.state.readonly) {
      return;
    }
    this.setState({
      data: {
        ...this.state.data,
        [key]: value
      },
      changed: true
    });
  }

  handleUserInput(e) {
    if (this.state.readonly) {
      return;
    }
    const { name, value } = e.target;
    this.setData(name, value);
  }

  loadAudio(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ file64: e.target.result });
    };
    reader.readAsDataURL(file);
  }

  renderToolbar() {
    return (
      <>
        <MainToolbar
          title={
            this.state.data.id
              ? this.state.readonly
                ? this.state.data.name
                : 'Alterar Música'
              : 'Editar Música'
          }
          renderLeft={() => this.renderBackButton()}
          renderRight={() => !this.state.readonly && this.renderButtonSave()}
        />
      </>
    );
  }

  renderBottomToolbar() {
    /*
    const {
      editionRequest,
      editionSign,
      editionPay,
      guideRequest,
      guidePay,
    } = songButtons(this.state.data, this.props.user.data);

    const showButtonSave = !this.state.readonly;

    const showEditionRequest = !this.state.changed
      && editionRequest;

    const showGuideRequest = !this.state.changed
      && guideRequest;

    const hasBottomToolbar = showButtonSave
      || showEditionRequest
      || showGuideRequest
      || editionSign
      || editionPay
      || guidePay;

    return hasBottomToolbar ? (
      <BottomToolbar modifier="material aligned" className="right">
        <div>
          {showButtonSave && this.renderButtonSave()}
          {showEditionRequest && (
            <ToolbarButton
              modifier="outline"
              onClick={() => this.props.history.push(Links.EDITION_REQUEST.replace(':songId', this.state.data.id))}
            >
              Solicitar Edição
            </ToolbarButton>
          )}

          {editionSign && (
            <ToolbarButton
              modifier="outline"
              onClick={() => this.props.history.push(Links.EDITION_SIGN.replace(':songId', this.state.data.id))}
            >
              Assinar Contrato de Edição
            </ToolbarButton>
          )}

          {showGuideRequest && (
            <ToolbarButton
              modifier="outline"
              onClick={() => this.props.history.push(Links.GUIDE_REQUEST.replace(':songId', this.state.data.id))}
            >
              Solicitar Guia
            </ToolbarButton>
          )}

          {editionPay && (
            <ToolbarButton
              modifier="outline"
              onClick={() => this.props.history.push(Links.EDITION_SIGNED_LIST.replace(':songId', this.state.data.id))}
            >
              Pagar Edição
            </ToolbarButton>
          )}
        </div>
      </BottomToolbar>
    ) : null;
    */

    return null;
  }

  renderActionButtons() {
    const {
      editionRequest,
      editionSign,
      editionPay,
      guideRequest,
      guidePay
    } = songButtons(this.state.data, this.props.user.data);

    const showButtonSave = !this.state.readonly;

    const showEditionRequest = !this.state.changed && editionRequest;

    const showGuideRequest = !this.state.changed && guideRequest;

    const hasBottomToolbar =
      showButtonSave ||
      showEditionRequest ||
      showGuideRequest ||
      editionSign ||
      editionPay ||
      guidePay;

    return hasBottomToolbar ? (
      <List>
        <ListItem>
          <div className="center">
            {showButtonSave && this.renderButtonSaveLarge()}

            {showEditionRequest && (
              <Button
                style={{ marginTop: 8 }}
                modifier="large outline"
                onClick={() =>
                  this.props.history.push(
                    Links.EDITION_REQUEST.replace(':songId', this.state.data.id)
                  )
                }
              >
                Solicitar Edição
              </Button>
            )}

            {editionSign && (
              <Button
                style={{ marginTop: 8 }}
                modifier="large outline"
                onClick={() =>
                  this.props.history.push(
                    Links.EDITION_SIGN.replace(':songId', this.state.data.id)
                  )
                }
              >
                Assinar Contrato de Edição
              </Button>
            )}

            {showGuideRequest && (
              <Button
                style={{ marginTop: 8 }}
                modifier="large outline"
                onClick={() =>
                  this.props.history.push(
                    Links.GUIDE_REQUEST.replace(':songId', this.state.data.id)
                  )
                }
              >
                Solicitar Guia
              </Button>
            )}

            {editionPay && (
              <Button
                style={{ marginTop: 8 }}
                modifier="large outline"
                onClick={() =>
                  this.props.history.push(
                    Links.EDITION_SIGNED_LIST.replace(
                      ':songId',
                      this.state.data.id
                    )
                  )
                }
              >
                Pagar Edição
              </Button>
            )}
          </div>
        </ListItem>
      </List>
    ) : null;
  }

  render() {
    const {
      id,
      name,
      lyrics,
      coauthors,
      audio_file_url,
      is_filled
    } = this.state.data;
    const { readonly } = this.state;

    return (
      <Page
        renderToolbar={() => this.renderToolbar()}
        renderBottomToolbar={() => this.renderBottomToolbar()}
      >
        {!is_filled && (
          <>
            <ListTitle>Instruções</ListTitle>
            <List>
              <ListItem>
                <p>
                  Para Editar a sua música, preencha o Título
                  {name ? (
                    <Icon icon="check" className="text-success ml-1" />
                  ) : id ? (
                    <Icon icon="times" className="text-danger ml-1" />
                  ) : null}
                  , a Letra
                  {lyrics ? (
                    <Icon icon="check" className="text-success ml-1" />
                  ) : id ? (
                    <Icon icon="times" className="text-danger ml-1" />
                  ) : null}
                  , os autores e envie o áudio no formato MP3
                  {audio_file_url ? (
                    <Icon icon="check" className="text-success ml-1" />
                  ) : id ? (
                    <Icon icon="times" className="text-danger ml-1" />
                  ) : null}
                  .
                </p>
              </ListItem>
            </List>
          </>
        )}
        <ListTitle>Informações da Música</ListTitle>
        <List>
          <ListItem>
            <div className="left form-label">
              <label>Título</label>
            </div>
            <div className="center">
              {readonly ? (
                name
              ) : (
                <Input
                  style={{ width: '100%' }}
                  maxLength={150}
                  float
                  disabled={false}
                  name="name"
                  value={name || ''}
                  onChange={(e) => this.handleUserInput(e)}
                  placeholder="Título da música"
                />
              )}
            </div>
            <div className="right">
              {!readonly && name && (
                <Icon className="text-success" icon="check" />
              )}
            </div>
          </ListItem>
          <ListItem>
            <div className="left form-label">
              <label>Letra</label>
            </div>
            <div className="center">
              {readonly ? (
                <pre>{lyrics}</pre>
              ) : (
                <textarea
                  style={{ width: '100%' }}
                  className="textarea textarea--transparent"
                  rows={6}
                  disabled={false}
                  name="lyrics"
                  value={lyrics || ''}
                  onChange={(e) => this.handleUserInput(e)}
                  placeholder="Informe a letra da música"
                />
              )}
            </div>
            <div className="right">
              {!readonly && lyrics && (
                <Icon className="text-success" icon="check" />
              )}
            </div>
          </ListItem>
        </List>

        <ListTitle>Autores</ListTitle>
        <List>
          {this.state.data.composerList &&
          this.state.data.composerList.length ? (
            this.state.data.composerList.map((author, index) => (
              <ListItem key={index}>
                <div className="left">
                  <img
                    className="list-item__thumbnail"
                    src={author.img_url_mini}
                    alt=""
                  />
                </div>
                <div className="center">{author.nickname}</div>
                <div className="right">
                  {this.state.data.solicita_edicao === '1' &&
                    author.signed_at && (
                      <div className="badge bg-success">Assinado</div>
                    )}
                  {this.state.data.solicita_edicao === '1' &&
                    !author.signed_at && (
                      <>
                        <div className="badge bg-danger">A assinar</div>
                      </>
                    )}
                </div>
              </ListItem>
            ))
          ) : (
            <>
              <ListItem>
                <div className="left form-label">
                  <label>Autor 1</label>
                </div>
                <div className="center">
                  {this.props.user.data.nickname || 'Você'}
                </div>
                <div className="right">
                  {!readonly && <Icon className="text-success" icon="check" />}
                </div>
              </ListItem>

              {coauthors.map((coauthor, index) => (
                <div key={index}>
                  {readonly ? (
                    coauthor.nickname && (
                      <>
                        <ListItem>
                          <div className="left form-label">
                            <label>Autor {index + 2}</label>
                          </div>
                          <div className="center">{coauthor.nickname}</div>
                        </ListItem>
                      </>
                    )
                  ) : (
                    <>
                      <ListItem>
                        <div className="left form-label">
                          <label>Autor {index + 2}</label>
                        </div>
                        <div className="center">
                          <Input
                            style={{ width: '100%' }}
                            maxLength={100}
                            float
                            disabled={false}
                            value={coauthor.nickname}
                            onChange={(e) => {
                              this.editAuthor(e.target.value, index);
                            }}
                            placeholder={`Pseudônimo do ${
                              index + 2
                            }º autor, se houver`}
                          />
                        </div>
                        <div className="right">
                          {coauthor.nickname && (
                            <Icon className="text-success" icon="check" />
                          )}
                        </div>
                      </ListItem>

                      {coauthor.nickname && (
                        <ListItem>
                          <div className="left form-label">
                            <label>E-mail de {coauthor.nickname}</label>
                          </div>
                          <div className="center">
                            <Input
                              style={{ width: '100%' }}
                              maxLength={100}
                              float
                              disabled={false}
                              type="email"
                              value={coauthor.email}
                              onChange={(e) => {
                                this.editEmail(e.target.value, index);
                              }}
                              placeholder={`E-mail de ${coauthor.nickname}`}
                            />
                          </div>
                          <div className="right">
                            {isEmailValid(coauthor.email) === true ? (
                              <Icon className="text-success" icon="check" />
                            ) : (
                              <Icon className="text-danger" icon="times" />
                            )}
                          </div>
                        </ListItem>
                      )}
                    </>
                  )}
                </div>
              ))}
            </>
          )}
        </List>

        <ListTitle>Áudio MP3</ListTitle>
        <List>
          {(this.state.file64 || audio_file_url) && (
            <ListItem>
              {readonly ? (
                audio_file_url && (
                  <div
                    className="center"
                    style={{ paddingTop: 12, paddingBottom: 12 }}
                  >
                    <ReactAudioPlayer src={audio_file_url} controls />
                  </div>
                )
              ) : (
                <>
                  <div className="center">
                    <div>
                      {!this.state.file64 && audio_file_url && (
                        <div>
                          <ReactAudioPlayer src={audio_file_url} controls />
                        </div>
                      )}

                      {this.state.file64 && (
                        <div>
                          <ReactAudioPlayer
                            src={this.state.file64}
                            autoPlay
                            controls
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="right">
                    {!readonly && (this.state.file64 || audio_file_url) && (
                      <Icon className="text-success" icon="check" />
                    )}
                    {!readonly && !audio_file_url && !this.state.file64 && (
                      <Icon className="text-danger" icon="times" />
                    )}
                  </div>
                </>
              )}
            </ListItem>
          )}

          <ListItem>
            {!readonly && (
              <>
                <div className="center">
                  <div>
                    {/* <Button
                      modifier="small outline"
                      onClick={() => {
                        document.getElementById('audio_fileFileUpload').click();
                      }}
                    >
                      {this.state.file64 || audio_file_url
                        ? 'Substituir Áudio'
                        : 'Selecionar Áudio'}
                    </Button> */}

                    <FileInput
                      id="audio_fileFileUpload"
                      type="file"
                      ref={this.fileInput}
                      accept="audio/mpeg"
                      onChange={(e) => {
                        this.setState({
                          file: e.target.files[0],
                          file64: null,
                          changed: true
                        });
                        this.loadAudio(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </ListItem>
        </List>

        {this.renderActionButtons()}

        <div style={{ height: 80 }} />

        <Modal isOpen={!!this.state.loading}>
          {this.state.uploadProgress ? (
            <ProgressCircular value={this.state.uploadProgress} />
          ) : (
            <ProgressCircular indeterminate />
          )}
          <div>{this.state.loading}</div>
        </Modal>
      </Page>
    );
  }
}

export default SongForm;
