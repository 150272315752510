import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Splitter,
  SplitterSide,
  SplitterContent,
  Modal,
  ProgressCircular
} from 'react-onsenui';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

import './styles.scss';

import MainToolbar from '../../components/MainToolbar';
import MainMenu from '../../components/MainMenu';
import api from '../../services/api';
import { login, logout } from '../../services/auth';

import Routes from '../routes';

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    const { setLoadingData, setData } = this.props.userActions;
    setLoadingData(true);
    api
      .get('/user/profile')
      .then((response) => {
        if (response.status === 200 && response.data && response.data.id) {
          setData(response.data);
        }
      })
      .finally(() => {
        setLoadingData(false);
      });

    const { history } = this.props;
    api
      .post('/user/token')
      .then((response) => {
        if (response.data && response.data.token) {
          login(response.data.token);
        } else if (response.data && response.data.logout) {
          logout();
          history.push('/');
        }
      })
      .catch(function () {
        console.log('Auth renew fail');
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.props.layoutActions.toggleIsMobile(window.innerWidth < 640);
  }

  hideMenu() {
    this.props.layoutActions.toggleMenu(false);
  }

  showMenu() {
    this.props.layoutActions.toggleMenu(true);
  }

  renderToolbar() {
    return <MainToolbar title="Inoveplay" />;
  }

  render() {
    return !this.props.user.isLogged ? (
      <Redirect to={{ pathname: '/', state: { from: this.props.location } }} />
    ) : (
      <div className="App">
        <Splitter>
          <SplitterSide
            style={
              this.props.layout.isMobile
                ? {
                    boxShadow:
                      '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
                  }
                : { borderRight: '1px solid #ccc' }
            }
            side="left"
            width={280}
            collapse={this.props.layout.isMobile}
            swipeable={this.props.layout.isMobile}
            swipeTargetWidth={80}
            isOpen={this.props.layout.isMenuOpen}
            onClose={this.hideMenu}
            onOpen={this.showMenu}
          >
            <MainMenu />
          </SplitterSide>

          <SplitterContent>
            {!this.props.user.loadingData && <Routes />}
          </SplitterContent>
        </Splitter>
        <Modal isOpen={!!this.props.user.loadingData}>
          <ProgressCircular indeterminate />
          <div>Carregando...</div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
