export const songButtons = (song, user) => {
  if (!song) {
    return {};
  }
  const removeDraft =
    song.account_id === user.id &&
    song.solicita_edicao !== '1' &&
    song.solicita_guia !== '1' &&
    song.published !== '1' &&
    true;

  const editionRequest =
    song.account_id === user.id &&
    song.is_filled &&
    song.solicita_edicao !== '1' &&
    song.published !== '1' &&
    true;

  const editionSign =
    song.solicita_edicao === '1' &&
    !song.signed_at &&
    song.published !== '1' &&
    true;

  const editionPay =
    song.account_id === user.id &&
    song.solicita_edicao === '1' &&
    song.signed_at &&
    song.contrato_edicao_payment_paid !== '1' &&
    true;

  const editionContractDownload = song.contrato_edicao_file_url && true;

  const guideRequest =
    song.account_id === user.id &&
    song.is_filled &&
    song.compliance_audio !== '1' &&
    song.solicita_guia !== '1' &&
    song.published !== '1' &&
    true;

  const guidePay =
    song.account_id === user.id &&
    song.solicita_guia === '1' &&
    song.guia_payment_paid !== '1' &&
    true;

  const isEditing =
    song.solicita_edicao === '1' &&
    song.contrato_edicao_payment_paid === '1' &&
    !song.contrato_edicao_file &&
    true;

  const isEdited =
    song.solicita_edicao === '1' &&
    song.contrato_edicao_payment_paid === '1' &&
    song.contrato_edicao_file &&
    true;

  const isPublished = song.published === '1' && song.short_url && true;

  return {
    removeDraft,
    editionRequest,
    editionSign,
    editionPay,
    editionContractDownload,
    guideRequest,
    guidePay,
    isEditing,
    isEdited,
    isPublished
  };
};
