import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Page, Icon, List, ListItem, ListTitle } from 'react-onsenui';
import ons from 'onsenui';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

import Links from '../../pages/links';
import './style.scss';

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.quitClick = this.quitClick.bind(this);
  }

  quitClick() {
    ons.notification
      .confirm('Deseja mesmo sair?', {
        title: 'Confirmação',
        buttonLabels: ['Voltar', 'Sair']
      })
      .then((data) => {
        if (data === 1) {
          this.props.userActions.toggleLogged(false);
          this.props.layoutActions.toggleMenu(false);
          this.props.history.push('/');
        }
      });
  }

  openLink(link) {
    this.props.layoutActions.toggleMenu(false);
    this.props.history.push(link);
  }

  render() {
    const { user } = this.props;

    return (
      <Page className="MainMenu">
        <List>
          <ListItem
            modifier="longdivider"
            onClick={() => this.openLink(Links.HOME)}
          >
            <div className="left">
              <img
                className="list-item__thumbnail"
                src={user.data.img_url_mini}
                alt=""
              />
            </div>
            <div className="center">
              <div className="list-item__title">{user.data.nickname}</div>
              <div className="list-item__subtitle">{user.data.email}</div>
            </div>
          </ListItem>
          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.PROFILE_FORM)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="user" />
            </div>
            <div className="center">Editar Perfil</div>
          </ListItem>

          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.PLAN)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="user-check" />
            </div>
            <div className="center">Meu Plano</div>
          </ListItem>

          {user.data.profile_url && (
            <>
              <ListItem
                modifier="chevron tappable"
                onClick={() => window.open(user.data.profile_url)}
              >
                <div className="left MainMenuLeft">
                  <Icon icon="link" />
                </div>
                <div className="center">Meu Site</div>
              </ListItem>
            </>
          )}
        </List>

        <ListTitle>Composições</ListTitle>
        <List>
          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.SONG_ADD)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="plus" />
            </div>
            <div className="center">Editar Música</div>
          </ListItem>

          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.SONGS)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="music" />
            </div>
            <div className="center">Status das Músicas</div>
          </ListItem>

          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.PUBLIC_SONGS)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="toggle-on" />
            </div>
            <div className="center">Organizar Músicas</div>
          </ListItem>

          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.DIGITAL_LIST)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="cash-register" />
            </div>
            <div className="center">Digital</div>
          </ListItem>
        </List>

        <ListTitle>Contratos de Edição</ListTitle>
        <List>
          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.EDITION_PENDING)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="file-contract" />
            </div>
            <div className="center">Contratos para Assinar</div>
          </ListItem>

          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.EDITION_SIGNED_LIST)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="clock" />
            </div>
            <div className="center">Edições Pendentes</div>
          </ListItem>
        </List>

        <ListTitle>Guias</ListTitle>
        <List>
          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.GUIDE_LIST)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="music" />
            </div>
            <div className="center">Solicitações de Guia</div>
          </ListItem>
        </List>

        <ListTitle>Dados</ListTitle>
        <List>
          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.PERSONAL_DATA)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="file" />
            </div>
            <div className="center">Dados Cadastrais</div>
          </ListItem>
          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.SIGNATURE)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="signature" />
            </div>
            <div className="center">Assinatura</div>
          </ListItem>
          <ListItem
            modifier="chevron tappable"
            onClick={() => this.openLink(Links.CHANGE_PASSWORD)}
          >
            <div className="left MainMenuLeft">
              <Icon icon="key" />
            </div>
            <div className="center">Alterar Senha</div>
          </ListItem>
        </List>

        <ListTitle>&nbsp;</ListTitle>
        <List>
          <ListItem modifier="chevron tappable" onClick={this.quitClick}>
            <div className="left MainMenuLeft">
              <Icon icon="sign-out-alt" />
            </div>
            <div className="center">Sair</div>
          </ListItem>
        </List>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainMenu)
);
