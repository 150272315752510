const Links = {
  HOME: '/app',
  PROFILE_FORM: '/app/profile',
  PERSONAL_DATA: '/app/contract-personal-data',
  SIGNATURE: '/app/signature',
  SONG_ADD: '/app/song-add',
  SONG_EDIT: '/app/song/:songId',
  SONGS: '/app/songs',
  PUBLIC_SONGS: '/app/public-songs',
  PLAN: '/app/plan',
  EDITION_REQUEST: '/app/edition-request/:songId',
  EDITION_SIGN: '/app/edition-sign/:songId',
  EDITION_PENDING: '/app/edition-pending',
  EDITION_SIGNED_LIST: '/app/edition-signed-list',
  EDITION_CONTRACT_LIST: '/app/edition-contract-list',
  GUIDE_REQUEST: '/app/guide-request/:songId',
  GUIDE_LIST: '/app/guide-list',
  CHANGE_PASSWORD: '/app/change-password',
  DIGITAL_LIST: '/app/digital-list'
};

export default Links;
