import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  toggleIsMobile: ['isMobile'],
  toggleMenu: ['isMenuOpen'],
});

const INITIAL_STATE = Immutable({
  isMobile: false,
  isMenuOpen: false,
  screen: null,
  options: {},
});

const toggleIsMobile = (state, action) => {
  return state.merge({
    isMobile: action.isMobile
  });
};

const toggleMenu = (state, action) => {
  return state.merge({
    isMenuOpen: action.isMenuOpen
  });
};

export default createReducer(INITIAL_STATE, {
  [Types.TOGGLE_IS_MOBILE]: toggleIsMobile,
  [Types.TOGGLE_MENU]: toggleMenu,
});
