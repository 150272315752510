import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { isAuthenticated, logout } from '../../services/auth';

/* Utils */
export const personalDataFilled = data =>
  data.name && data.birthdate && data.naturalidade && data.estado_civil && data.rg && data.rg_orgao && data.cpf && data.phone_number && data.address_postcode && data.address_line1 && data.address_line2 && data.address_neighborhood && data.address_city && data.address_state
  ;

/* Duck pattern */

export const { Types, Creators } = createActions({
  toggleLogged: ['logged'],
  checkIsAuthenticated: [],
  setLoadingData: ['loading'],
  setData: ['data'],
});

const INITIAL_STATE = Immutable({
  isLogged: isAuthenticated() ? true : false,
  loadingData: true,
  data: {
    id: "",
    nickname: "",
    name: "",
    email: "",
  },
  personalDataFilled: false,
});

const toggleLogged = (state, action) => {
  if (!action.logged) {
    logout();
  }
  return state.merge({
    isLogged: action.logged
  });
};

const checkIsAuthenticated = (state, action) => {
  return toggleLogged(state, { logged: isAuthenticated() });
};

const setData = (state, action) => {
  return state.merge({
    data: action.data,
    personalDataFilled: personalDataFilled(action.data),
  });
};

const setLoadingData = (state, action) => {
  return state.merge({
    loadingData: action.loading,
  });
};

export default createReducer(INITIAL_STATE, {
  [Types.TOGGLE_LOGGED]: toggleLogged,
  [Types.SET_DATA]: setData,
  [Types.SET_LOADING_DATA]: setLoadingData,
  [Types.CHECK_IS_AUTHENTICATED]: checkIsAuthenticated,
});
