import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  BackButton,
  BottomToolbar,
  // Icon,
  // Input,
  List,
  ListItem,
  ListTitle,
  Modal,
  Page,
  ProgressCircular,
  ToolbarButton
} from 'react-onsenui';
import ons from 'onsenui';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

// import AvatarEditor from 'react-avatar-editor'
import MainToolbar from '../../components/MainToolbar';
import api from '../../services/api';
import Links from '../links';
// import { Container } from './styles';

import imgInstrucoesAssinatura from '../../assets/instrucoes-assinatura.png';

class PersonalSignature extends Component {
  constructor(props) {
    super(props);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.fileInput = React.createRef();
  }

  state = {
    loading: false,
    data: this.props.user.data,
    changed: false,
    file: null,
    file64: null
  };

  async componentDidMount() {
    this.loadProfile();
  }

  async loadProfile() {
    try {
      this.setState({ loading: 'Carregando...' });

      const { data } = await api.get('/user/profile');
      this.setState({
        data,
        changed: false,
        file: null,
        file64: null
      });
      this.props.userActions.setData(data);
    } catch (err) {
      ons.notification
        .confirm('Não foi possível carregar os dados.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Sair']
        })
        .then((data) => {
          if (data === 0) {
            this.loadProfile();
          } else if (data === 1) {
            this.props.history.push(Links.HOME);
          }
        });
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  async saveProfile() {
    if (!this.state.changed) {
      return;
    }
    try {
      this.setState({ loading: 'Salvando...' });

      if (this.state.file64) {
        const formData = new FormData();
        const { file } = this.state;
        formData.append('img', file, file.name);
        await api.post('/user/signature', formData, {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
          },
          timeout: 600000
        });
      }

      this.setState({
        changed: false,
        file: null,
        file64: null
      });
      this.loadProfile();
    } catch (err) {
      ons.notification
        .confirm('Não foi possível salvar.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Cancelar']
        })
        .then((data) => {
          if (data === 0) {
            this.saveProfile();
          }
        });
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  loadImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ file64: e.target.result });
    };
    reader.readAsDataURL(file);
  }

  renderBackButton() {
    return (
      <BackButton
        onClick={() => this.props.history.push(Links.EDITION_PENDING)}
      >
        Voltar
      </BackButton>
    );
  }

  renderButtonSave() {
    return (
      <ToolbarButton
        modifier="outline"
        disabled={!!(!this.state.changed || this.state.loading)}
        onClick={() => this.saveProfile()}
      >
        Salvar
      </ToolbarButton>
    );
  }

  renderToolbar() {
    return (
      <>
        <MainToolbar
          title="Minha Assinatura"
          renderLeft={() => this.renderBackButton()}
          renderRight={() => this.renderButtonSave()}
        />
        <BottomToolbar modifier="material aligned" className="right">
          <div>{this.renderButtonSave()}</div>
        </BottomToolbar>
      </>
    );
  }

  render() {
    const { img_signature_url } = this.state.data;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <ListTitle>Instruções</ListTitle>
        <List>
          <ListItem>
            Sua assinatura será utilizada nos contratos de edição das músicas
            que você enviar ou que você for co-autor. A coleta de assinatura
            precisa ser feita apenas uma vez. A imagem de sua assinatura será
            utilizada sempre que você clicar no botão para Assinar o contrato.
            Por favor, mantenha a imagem de sua assinatura sempre atualizada.
          </ListItem>
        </List>

        <ListTitle>Passo-a-passo</ListTitle>
        <List>
          <ListItem>
            <ol>
              <li>
                Pegue uma folha em branco, lisa, sem marca d'água ou linhas;
              </li>
              <li>
                Com uma caneta esferográfica azul ou preta, escreva{' '}
                <strong>duas</strong> vezes sua assinatura, deixando um espaço
                de aproximadamente <strong>4 cm</strong> entre elas, para que
                não se sobrescrevam;
              </li>
              <li>Fotografe as assinaturas;</li>
              <li>
                Clique em <q>Anexar nova imagem</q> e insira uma foto da sua
                assinatura.
              </li>
            </ol>

            <div style={{ paddingLeft: 20, paddingRight: 20, maxWidth: 300 }}>
              <img src={imgInstrucoesAssinatura} alt="" className="img-fluid" />
            </div>
          </ListItem>
        </List>

        <ListTitle>Imagem</ListTitle>
        <List>
          <ListItem>
            <div className="left form-label">
              <label>Imagem da Assinatura</label>
            </div>
            <div className="center">
              <div>
                <div style={{ maxWidth: 300 }}>
                  <img
                    src={
                      this.state.file64 ||
                      img_signature_url ||
                      this.props.user.data.img_signature_url ||
                      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                    }
                    alt="Imagem de perfil"
                    style={{
                      maxWidth: '100%',
                      maxHeight: 200,
                      borderRadius: 4,
                      background: '#e5e5e5'
                    }}
                    onClick={() => {
                      document.getElementById('editarPerfilFileUpload').click();
                    }}
                  />
                </div>

                <Button
                  modifier="small outline"
                  onClick={() => {
                    document.getElementById('editarPerfilFileUpload').click();
                  }}
                >
                  Anexar Nova Imagem
                </Button>

                <input
                  id="editarPerfilFileUpload"
                  type="file"
                  ref={this.fileInput}
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.setState({
                      file: e.target.files[0],
                      file64: null,
                      changed: true
                    });
                    this.loadImage(e.target.files[0]);
                  }}
                  style={{ width: 0, height: 0 }}
                />
              </div>
            </div>
          </ListItem>
        </List>

        <Modal isOpen={!!this.state.loading}>
          <ProgressCircular indeterminate />
          <div>{this.state.loading}</div>
        </Modal>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalSignature);
