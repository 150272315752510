import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

import {
  Button,
  BackButton,
  //BottomToolbar,
  Icon,
  //Input,
  List,
  ListItem,
  ListTitle,
  Modal,
  Page,
  ProgressCircular,
  //Select,
  ToolbarButton,
} from 'react-onsenui';
import ons from 'onsenui';

//import AvatarEditor from 'react-avatar-editor'
import MainToolbar from '../../components/MainToolbar';
import api from '../../services/api';
import Links from '../links';
// import { Container } from './styles';

class EditionSign extends Component {
  constructor(props) {
    super(props);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.fileInput = React.createRef();
  }

  state = {
    loading: false,
    data: {
      id: "",
      name: "",
      lyrics: "",
      coauthors: [
        { nickname: "", email: "" },
      ],
      audio_file_url: "",
    },
    previewHtml: "",
  };

  async componentDidMount() {
    if (!this.props.user.data.nickname) {
      return this.props.history.push(Links.PROFILE_FORM);
    }
    if (!this.props.user.personalDataFilled) {
      return this.props.history.push(Links.PERSONAL_DATA);
    }
    if (!this.props.user.data.img_signature_url) {
      this.props.history.push(Links.SIGNATURE);
      return;
    }

    const { songId } = this.props.match.params;
    this.loadSong(songId);
  }

  async loadSong(id) {
    if (!id) {
      this.props.history.push(Links.SONGS);
      return;
    }

    try {
      this.setState({ loading: "Carregando..." });

      const { data } = await api.get('/user/songs/' + id);

      const preview = await api.get('/user/songs/' + id + '/edition-preview');

      this.setState({
        data,
        previewHtml: preview.data.html,
        loading: false,
      });

    } catch (err) {
      if (err && err.status === 404) {
        this.props.history.push(Links.SONGS);
        return;
      }
      this.setState({ loading: false });
      ons.notification.confirm('Não foi possível carregar os dados.', {
        title: 'Erro',
        buttonLabels: ["Tentar de novo", "Sair"],
      }).then(data => {
        if (0 === data) {
          this.loadSong();
        } else if (1 === data) {
          this.props.history.push(Links.SONGS);
        }
      });
    }
  }

  async assinar() {
    this.setState({ loading: "Assinando..." });

    try {
      const response = await api.post('/user/songs/' + this.state.data.id + '/sign');

      if (response && response.data && response.data.id) {
        this.props.history.push(Links.EDITION_SIGNED_LIST);
      } else {
        ons.notification.confirm('Não foi possível assinar.', {
          title: 'Erro',
          buttonLabels: ["Tentar de novo", "Cancelar"],
        }).then(data => {
          if (0 === data) {
            this.assinar();
          }
        });
        this.setState({ loading: false });
      }
    } catch (err) {
      ons.notification.confirm('Não foi possível assinar. ' + ((err && err.data && err.data.message) || ""), {
        title: 'Erro',
        buttonLabels: ["Tentar de novo", "Cancelar"],
      }).then(data => {
        if (0 === data) {
          this.assinar();
        }
      });

      this.setState({ loading: false });
    }
  }

  assinarClick() {
    ons.notification.confirm('Confirma assinar este contrato?', {
      title: '',
      buttonLabels: ["Sim", "Cancelar"],
    }).then(data => {
      if (0 === data) {
        this.assinar();
      }
    });
  }

  renderBackButton() {
    return (
      <BackButton
        onClick={() => { this.props.history.push(Links.SONGS); }}
      >Voltar</BackButton>
    );
  }

  renderButtonSave() {
    return (
      <ToolbarButton
        modifier="outline"
        disabled={this.state.loading ? true : false}
        onClick={() => { this.save(); }}
      >
        Assinar
      </ToolbarButton>
    );
  }

  renderToolbar() {
    return (
      <>
        <MainToolbar
          title={this.state.data.name}
          renderLeft={() => this.renderBackButton()}
        />
      </>
    );
  }

  render() {
    return (
      <Page renderToolbar={this.renderToolbar}>
        <ListTitle>Instruções</ListTitle>
        <List>
          <ListItem>
            <p>
              Leia atentamente a pré-visualização do Contrato de Edição da sua música. Estando de acordo com todos os termos, clique em <b>Assinar</b>.
            </p>
          </ListItem>
        </List>

        <ListTitle>Pré-visualização do Contrato</ListTitle>
        <List>
          <ListItem>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: this.state.previewHtml }}></div>
          </ListItem>
        </List>



        <ListTitle>Assinatura</ListTitle>
        <List>
          <ListItem>
            <div style={{ width: '100%' }}>
              <div style={{ maxWidth: 300, margin: 'auto' }}>
                <img
                  src={this.props.user.data.img_signature_url}
                  alt="Assinatura"
                  style={{ maxWidth: '100%', maxHeight: 200, borderRadius: 4, background: "#e5e5e5" }}
                  onClick={() => {
                    document.getElementById('editarPerfilFileUpload').click();
                  }}
                />
              </div>

              <div style={{ paddingTop: 12, paddingBottom: 12 }}>
                <Button
                  modifier="large"
                  onClick={() => this.assinarClick()}
                >
                  Assinar <Icon style={{ marginLeft: 8 }} icon="chevron-right" />
                </Button>
              </div>
            </div>
          </ListItem>
        </List>


        <div style={{ height: 80 }}></div>

        <Modal isOpen={this.state.loading ? true : false}>
          <ProgressCircular indeterminate />
          <div>{this.state.loading}</div>
        </Modal>
      </Page >
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditionSign));
