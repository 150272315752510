import SongForm from "../../components/SongForm";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

class SongAdd extends SongForm { };

const mapStateToProps = state => ({
  user: state.user,
  layout: state.layout,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SongAdd);