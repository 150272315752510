import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SongList from '../../components/SongList';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

class Songs extends SongList {}

const mapStateToProps = (state) => ({
  user: state.user,
  layout: state.layout,
  title: 'Status das Músicas',
  endpoint: '/user/songs',
  addButton: true
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Songs);
