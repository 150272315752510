import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  BackButton,
  // BottomToolbar,
  Icon,
  Row,
  Col,
  // Input,
  List,
  ListItem,
  ListTitle,
  Modal,
  Page,
  ProgressCircular,
  Switch,
  ToolbarButton
} from 'react-onsenui';
import ons from 'onsenui';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

// import AvatarEditor from 'react-avatar-editor'
import MainToolbar from '../../components/MainToolbar';
import api from '../../services/api';
import Links from '../links';
import { reaisMask } from '../../components/masks';
// import { Container } from './styles';

class Plan extends Component {
  constructor(props) {
    super(props);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.fileInput = React.createRef();
  }

  state = {
    loading: false,
    data: this.props.user.data,
    plans: [],
    changed: false,
    composerPlan: {}
  };

  async componentDidMount() {
    this.loadProfile();
  }

  async loadProfile() {
    try {
      this.setState({ loading: 'Carregando...' });

      const rprofile = await api.get('/user/profile');

      this.props.userActions.setData(rprofile.data);

      const rplans = await api.get('/user/plans');

      let composerPlan;
      if (rprofile.data.current_plan_id === rprofile.data.composer_plan_id) {
        composerPlan = rprofile.data.current_plan;
      } else {
        rplans.data.forEach((plan) => {
          if (plan.id === rprofile.data.composer_plan_id) {
            composerPlan = plan;
          }
        });
      }

      this.setState({
        data: rprofile.data,
        plans: rplans.data,
        changed: false,
        loading: false,
        composerPlan
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      ons.notification
        .confirm('Não foi possível carregar.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Sair']
        })
        .then((data) => {
          if (data === 0) {
            this.loadProfile();
          } else if (data === 1) {
            this.props.history.push(Links.HOME);
          }
        });
    }
  }

  async saveProfile() {
    if (!this.state.changed) {
      return;
    }
    try {
      const { composer_plan_id } = this.state.data;

      this.setState({ loading: 'Salvando...' });
      await api.post('/user/plan', {
        composer_plan_id
      });

      this.setState({
        changed: false
      });
      await this.loadProfile();

      if (
        composer_plan_id !== this.props.user.data.current_plan_id &&
        parseInt(composer_plan_id) > 1
      ) {
        this.doGenerateBill();
      }
    } catch (err) {
      ons.notification
        .confirm('Não foi possível salvar.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Cancelar']
        })
        .then((data) => {
          if (data === 0) {
            this.saveProfile();
          }
        });
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  doGenerateBill() {
    const { composerPlan } = this.state;
    // TODO: Generate Bill
    ons.notification
      .confirm(
        `Ative o plano <strong>${composerPlan.name}</strong>${
          composerPlan.monthly_price &&
          ` por ${reaisMask(this.state.composerPlan.monthly_price)}`
        }`,
        {
          title: 'Ativação de Plano',
          buttonLabels: ['Pagar', 'Depois']
        }
      )
      .then((data) => {
        if (data === 0) {
          const boletoUrl = this.props.user.data.composer_plan_payment_url;
          if (boletoUrl) {
            const wo = window.open(boletoUrl, '_blank');
            if (!wo) {
              const messageHTML = `<a
                  href="${boletoUrl}"
                  rel="noopener noreferrer"
                  target="_blank"
                >Clique aqui</a> para abrir o boleto.`;
              ons.notification
                .alert({
                  title: 'Aviso',
                  messageHTML,
                  buttonLabels: ['Fechar']
                })
                .then(() => {});
            }
          } else {
            ons.notification.alert('Falha ao gerar boleto.');
          }
        }
      });
  }

  async selectPlan(planId) {
    await this.setState({
      data: {
        ...this.state.data,
        composer_plan_id: planId
      },
      changed: this.props.user.data.composer_plan_id !== planId
    });

    this.saveProfile();
  }

  renderBackButton() {
    return (
      <BackButton
        onClick={() => {
          this.props.history.goBack();
        }}
      >
        Voltar
      </BackButton>
    );
  }

  renderButtonSave() {
    return (
      <ToolbarButton
        modifier="outline"
        disabled={!!(!this.state.changed || this.state.loading)}
        onClick={() => {
          this.saveProfile();
        }}
      >
        Salvar
      </ToolbarButton>
    );
  }

  renderToolbar() {
    return (
      <>
        <MainToolbar
          title="Meu Plano"
          renderLeft={() => this.renderBackButton()}
        />
      </>
    );
  }

  render() {
    const { data, plans, composerPlan } = this.state;
    const plan = this.props.user.data.current_plan;
    const { current_plan_id, account_balance } = this.props.user.data;

    const account_balance_int = account_balance ? parseInt(account_balance) : 0;

    return (
      <Page renderToolbar={this.renderToolbar}>
        {account_balance_int !== 0 && (
          <>
            <ListTitle>InoveCoins</ListTitle>
            <List>
              <ListItem>
                Você já acumulou&nbsp;
                <strong>{account_balance_int}</strong>
                &nbsp;{account_balance_int !== 1 ? 'InoveCoins' : 'InoveCoin'}.
              </ListItem>
            </List>
          </>
        )}

        {composerPlan.id && plan.id !== composerPlan.id && (
          <>
            <ListTitle>Troca de Plano</ListTitle>
            <List>
              <ListItem>
                <div>
                  <div style={{ marginBottom: 8 }}>
                    Seu plano ativo neste momento é <strong>{plan.name}</strong>
                    .
                  </div>
                  {parseFloat(composerPlan.monthly_price) ? (
                    <>
                      <p>
                        Ative o plano <strong>{composerPlan.name}</strong>:
                      </p>
                      <Button onClick={() => this.doGenerateBill()}>
                        Pagar
                      </Button>

                      <p>
                        <small>
                          Obs.: caso já tenha pago, por favor aguarde a
                          compensação bancária, que pode levar até 3 dias úteis.
                          Qualquer dúvida, entre em contato conosco.
                        </small>
                      </p>
                    </>
                  ) : (
                    <p>
                      O plano <strong>{composerPlan.name}</strong> será ativado
                      assim que o plano atual expirar.
                    </p>
                  )}
                </div>
              </ListItem>
            </List>
          </>
        )}

        <ListTitle>Plano Ativo</ListTitle>
        <List>
          <ListItem
            modifier="tappable"
            onClick={() => this.selectPlan(plan.id)}
          >
            <div className="left">
              <Switch checked={data.composer_plan_id === plan.id} />
            </div>
            <div className="center">
              <h2>{plan.name}</h2>
              <p style={{ minWidth: '100%' }}>{plan.description}</p>
              <Row className="mb-2">
                <Col>Mensalidade</Col>
                <Col>
                  {parseFloat(plan.monthly_price) > 0 ? (
                    <>
                      {reaisMask(plan.monthly_price)}
                      <small>/mês</small>
                    </>
                  ) : (
                    <span className="text-success">
                      Gratuito <Icon icon="check" />
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>Edição</Col>
                <Col>
                  {plan.edicao_price_original !== plan.edicao_price ? (
                    <>
                      {reaisMask(plan.edicao_price)}
                      <br />
                      <small className="text-success">
                        desc. de{' '}
                        <span className="text-success">
                          {reaisMask(
                            plan.edicao_price_original - plan.edicao_price
                          )}{' '}
                          <Icon icon="check" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>{reaisMask(plan.edicao_price)}</>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>Guia</Col>
                <Col>
                  {plan.guia_price_original !== plan.guia_price ? (
                    <>
                      {reaisMask(plan.guia_price)}
                      <br />
                      <small className="text-success">
                        desc. de{' '}
                        <span className="text-success">
                          {reaisMask(
                            plan.guia_price_original - plan.guia_price
                          )}{' '}
                          <Icon icon="check" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>{reaisMask(plan.guia_price)}</>
                  )}
                </Col>
              </Row>
            </div>
          </ListItem>
        </List>
        <ListTitle>Planos Disponíveis</ListTitle>
        <List>
          {plans.map((plan, index) =>
            plan.id === current_plan_id ? null : (
              <ListItem
                key={index}
                modifier="tappable"
                onClick={() => this.selectPlan(plan.id)}
              >
                <div className="left">
                  <Switch checked={data.composer_plan_id === plan.id} />
                </div>
                <div className="center">
                  <h2>{plan.name}</h2>
                  <p style={{ minWidth: '100%' }}>{plan.description}</p>
                  <Row className="mb-2">
                    <Col>Mensalidade</Col>
                    <Col>
                      {parseFloat(plan.monthly_price) > 0 ? (
                        <>
                          {reaisMask(plan.monthly_price)}
                          <small>/mês</small>
                        </>
                      ) : (
                        <span className="text-success">
                          Gratuito <Icon icon="check" />
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>Edição</Col>
                    <Col>
                      {plan.edicao_price_original !== plan.edicao_price ? (
                        <>
                          {reaisMask(plan.edicao_price)}
                          <br />
                          <small className="text-success">
                            desc. de{' '}
                            <span className="text-success">
                              {reaisMask(
                                plan.edicao_price_original - plan.edicao_price
                              )}{' '}
                              <Icon icon="check" />
                            </span>
                          </small>
                        </>
                      ) : (
                        <>{reaisMask(plan.edicao_price)}</>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>Guia</Col>
                    <Col>
                      {plan.guia_price_original !== plan.guia_price ? (
                        <>
                          {reaisMask(plan.guia_price)}
                          <br />
                          <small className="text-success">
                            desc. de{' '}
                            <span className="text-success">
                              {reaisMask(
                                plan.guia_price_original - plan.guia_price
                              )}{' '}
                              <Icon icon="check" />
                            </span>
                          </small>
                        </>
                      ) : (
                        <>{reaisMask(plan.guia_price)}</>
                      )}
                    </Col>
                  </Row>
                </div>
              </ListItem>
            )
          )}
        </List>

        <Modal isOpen={!!this.state.loading}>
          <ProgressCircular indeterminate />
          <div>{this.state.loading}</div>
        </Modal>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
