import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Page } from 'react-onsenui';

import preval from 'preval.macro';

import { Section, Card, Button } from './styles';
import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

import Links from '../links';
import MainToolbar from '../../components/MainToolbar';
import logomarca164x50 from '../../assets/logomarca164x50.png';

const buildVersion = preval`module.exports = (()=>{
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = parseInt('' + d.getDate()),
    year = d.getYear()-119,
    hour = d.getHours(),
    min = d.getMinutes(),
    maxBuild = 9999,
    build = (Math.round(
      (day * 1440 + hour * 60 + min)/
      ((31 * 1440 + 23 * 60 + 59) / maxBuild)))
      .toString(10).toUpperCase();

  if (day.length < 2) {
    day = '0' + day;
  }

  return ['v', year, '.', month, '.', build].join('');
})();`;

class Home extends Component {
  renderToolbar() {
    return (
      <MainToolbar
        title={
          <img
            src={logomarca164x50}
            alt="Inoveplay"
            height="26"
            style={{ marginTop: 8 }}
          />
        }
        renderRight={() => (
          <small
            className="text-muted"
            style={{ marginRight: 8, fontSize: '0.6rem' }}
          >
            {buildVersion}
          </small>
        )}
      />
    );
  }

  render() {
    const {
      nickname,
      profile_url,
      id,
      img_url,
      img_signature_url,
      composer_plan_id,
      current_plan_id
    } = this.props.user.data;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <Section>
          <p>Bem-vindo(a){nickname && `, ${nickname}`}!</p>

          {id && !nickname && (
            <Card>
              <p>
                Para começar, vamos criar o seu perfil. Por favor, preencha o
                seu pseudônimo.
              </p>
              <Button
                modifier=""
                onClick={() => {
                  this.props.history.push(Links.PROFILE_FORM);
                }}
              >
                Editar Perfil
              </Button>
            </Card>
          )}

          {id && nickname && !img_url && (
            <Card>
              <p>
                O seu perfil está quase pronto. Por favor, insira uma foto sua
                para divulgação.
              </p>
              <Button
                modifier=""
                onClick={() => {
                  this.props.history.push(Links.PROFILE_FORM);
                }}
              >
                Editar Perfil
              </Button>
            </Card>
          )}

          {composer_plan_id !== current_plan_id &&
            parseInt(composer_plan_id) > 1 && (
              <Card>
                <p>
                  Você selecionou um novo plano, mas ele ainda não está ativo.
                </p>

                <Button
                  modifier=""
                  onClick={() => {
                    this.props.history.push(Links.PLAN);
                  }}
                >
                  Meu Plano
                </Button>
              </Card>
            )}

          {id && nickname && !this.props.user.personalDataFilled && (
            <Card>
              <p>Complete seu cadastro para editar as suas músicas.</p>

              <Button
                modifier=""
                onClick={() => {
                  this.props.history.push(Links.PERSONAL_DATA);
                }}
              >
                Dados Cadastrais
              </Button>
            </Card>
          )}

          {id &&
            nickname &&
            this.props.user.personalDataFilled &&
            !img_signature_url && (
              <Card>
                <p>
                  Envie sua assinatura para ser utilizada nos Contratos de
                  Edição.
                </p>

                <Button
                  modifier=""
                  onClick={() => {
                    this.props.history.push(Links.SIGNATURE);
                  }}
                >
                  Enviar Assinatura
                </Button>
              </Card>
            )}

          {id && profile_url && (
            <>
              <Card>
                <p>Visite a sua página exclusiva:</p>

                <Button
                  modifier="outline"
                  onClick={() => {
                    window.open(profile_url);
                  }}
                >
                  Meu Site
                </Button>
              </Card>

              <Card>
                <p>
                  Adicione uma nova música para Editar e/ou solicitar confecção
                  de Guia:
                </p>

                <Button
                  modifier="outline"
                  onClick={() => {
                    this.props.history.push(Links.SONG_ADD);
                  }}
                >
                  Editar Música
                </Button>
              </Card>

              <Card>
                <p>Veja as suas músicas:</p>

                <Button
                  modifier="outline"
                  onClick={() => {
                    this.props.history.push(Links.SONGS);
                  }}
                >
                  Status das Músicas
                </Button>
              </Card>
            </>
          )}
        </Section>
      </Page>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
