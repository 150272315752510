import React from 'react';
import { Route, Link } from 'react-router-dom';

import { Page } from 'react-onsenui';

import Home from './Home';
import ProfileForm from './ProfileForm';
import SongAdd from './SongAdd';
import SongEdit from './SongEdit';
import Songs from './Songs';
import PublicSongs from './PublicSongs';
import Plan from './Plan';
import Links from './links';
import PersonalData from './PersonalData';
import PersonalSignature from './PersonalSignature';
import EditionRequest from './EditionRequest';
import EditionSign from './EditionSign';
import EditionPending from './EditionPending';
import EditionSignedList from './EditionSignedList';
import EditionPaidList from './EditionPaidList';
import GuideRequest from './GuideRequest';
import GuideList from './GuideList';
import ChangePassword from './ChangePassword';
import DigitalList from './DigitalList';

const Routes = () => (
  <>
    <Route
      component={() => (
        <Page>
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            Oops! Página não encontrada.
            <br />
            <br />
            <Link to={Links.HOME}>Ir para página inicial</Link>
          </div>
        </Page>
      )}
    />
    <Route exact path={Links.HOME} component={Home} />
    <Route exact path={Links.PLAN} component={Plan} />
    <Route exact path={Links.PROFILE_FORM} component={ProfileForm} />
    <Route exact path={Links.SONG_ADD} component={SongAdd} />
    <Route exact path={Links.SONG_EDIT} component={SongEdit} />
    <Route exact path={Links.SONGS} component={Songs} />
    <Route exact path={Links.PUBLIC_SONGS} component={PublicSongs} />
    <Route exact path={Links.PERSONAL_DATA} component={PersonalData} />
    <Route exact path={Links.SIGNATURE} component={PersonalSignature} />
    <Route exact path={Links.DIGITAL_LIST} component={DigitalList} />
    <Route exact path={Links.EDITION_REQUEST} component={EditionRequest} />
    <Route exact path={Links.EDITION_SIGN} component={EditionSign} />
    <Route exact path={Links.EDITION_PENDING} component={EditionPending} />
    <Route
      exact
      path={Links.EDITION_SIGNED_LIST}
      component={EditionSignedList}
    />
    <Route
      exact
      path={Links.EDITION_CONTRACT_LIST}
      component={EditionPaidList}
    />
    <Route exact path={Links.GUIDE_REQUEST} component={GuideRequest} />
    <Route exact path={Links.GUIDE_LIST} component={GuideList} />
    <Route exact path={Links.CHANGE_PASSWORD} component={ChangePassword} />
  </>
);

export default Routes;
