import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Input, Modal, Page, ProgressCircular } from 'react-onsenui';
import ons from 'onsenui';
import { Creators as UserActions } from '../../store/ducks/user';
// import { Creators as LayoutActions } from '../../store/ducks/layout';
import api from '../../services/api';
import { login } from '../../services/auth';

import './styles.scss';
import logomarca512 from '../../assets/logomarca512.png';

class SignIn extends Component {
  _isMounted = false;

  state = {
    email: '',
    pwd: '',
    trying: false
  };

  showError(error) {
    ons.notification.alert(error, { title: 'Erro' });
    // this.setState({ error });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSignIn = async (e) => {
    e.preventDefault();

    if (this.state.trying) {
      return;
    }

    const { email, pwd } = this.state;

    if (!email.length) {
      this.showError('Preencha o e-mail.');
      return;
    }

    if (!pwd.length) {
      this.showError('Preencha a senha.');
      return;
    }

    this.setState({
      trying: true
    });

    try {
      const response = await api.post('/user/login', {
        email,
        pwd
      });

      if (response.data.token) {
        const { token } = response.data;

        login(token);
        this.props.userActions.checkIsAuthenticated();
        this.props.history.push('/app');
        return; // must return after a push, because it will unmount the component
      }
      this.showError('Falha na autenticação. Tente novamente.');
      this.setState({ trying: false });
    } catch (error) {
      this.showError(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Erro ao conectar.'
      );
      console.log(error);
      this.setState({ trying: false });
    }
  };

  render() {
    const { email, pwd } = this.state;
    return (
      <Page>
        <form onSubmit={this.handleSignIn} className="Login">
          <div>
            <img src={logomarca512} alt="Inoveplay" width="192" height="192" />
          </div>
          <div style={{ marginBottom: 8 }}>Possuo uma conta</div>
          <Input
            disabled={this.state.trying}
            value={email}
            float
            onChange={(event) => {
              this.setState({ email: event.target.value.toLowerCase() });
            }}
            modifier="underbar"
            placeholder="E-mail"
          />
          <br />
          <Input
            disabled={this.state.trying}
            value={pwd}
            float
            type="password"
            onChange={(event) => {
              this.setState({ pwd: event.target.value });
            }}
            modifier="underbar"
            placeholder="Senha"
          />
          <br />

          <Button
            disabled={this.state.trying}
            onClick={(e) => this.handleSignIn(e)}
          >
            Conectar
          </Button>
          <div style={{ marginTop: 16, width: '100%' }}>
            <Link to="/recover">Recuperar senha</Link>

            <Link style={{ marginLeft: 16 }} to="/signup">
              Criar conta grátis
            </Link>
          </div>
        </form>

        <Modal isOpen={!!this.state.trying}>
          <ProgressCircular indeterminate />
          <div>Conectando...</div>
        </Modal>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
