import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

import {
  Button,
  BackButton,
  //BottomToolbar,
  Icon,
  //Input,
  List,
  ListItem,
  ListTitle,
  Modal,
  Page,
  ProgressCircular,
  //Select,
  ToolbarButton,
} from 'react-onsenui';
import ons from 'onsenui';

import ReactAudioPlayer from 'react-audio-player';

//import AvatarEditor from 'react-avatar-editor'
import MainToolbar from '../../components/MainToolbar';
import api from '../../services/api';
import Links from '../links';
import { reaisMask } from '../../components/masks';
// import { Container } from './styles';

class GuideRequest extends Component {
  constructor(props) {
    super(props);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.fileInput = React.createRef();
  }

  state = {
    loading: false,
    data: {
      id: "",
      name: "",
      lyrics: "",
      coauthors: [
        { nickname: "", email: "" },
      ],
      audio_file_url: "",
    },
    edicao_tipo: "padrao",
    edicao_observacoes: "",
    edicao_autores_nao_editados: "",
  };

  async componentDidMount() {
    const { songId } = this.props.match.params;

    this.loadSong(songId);
  }

  async loadSong(id) {
    if (!id) {
      this.props.history.push(Links.SONGS);
      return;
    }

    try {
      this.setState({ loading: "Carregando..." });

      const { data } = await api.get('/user/songs/' + id);

      if (data.solicita_guia === '1') {
        this.props.history.push(Links.SONGS);
        return;
      }

      this.setState({
        data,
        loading: false,
        readonly: data.status && data.status !== 'draft',
      });
    } catch (err) {
      if (err && err.status === 404) {
        this.props.history.push(Links.SONGS);
        return;
      }
      this.setState({ loading: false });
      ons.notification.confirm('Não foi possível carregar os dados.', {
        title: 'Erro',
        buttonLabels: ["Tentar de novo", "Sair"],
      }).then(data => {
        if (0 === data) {
          this.loadSong();
        } else if (1 === data) {
          this.props.history.push(Links.SONGS);
        }
      });
    }
  }

  async solicitarGuia() {
    this.setState({ loading: "Solicitando Guia..." });

    try {
      const response = await api.post('/user/songs/' + this.state.data.id + '/guia');

      if (response && response.data && response.data.id) {
        if (response.data.guia_payment_url) {
          window.open(response.data.guia_payment_url);
        }

        this.props.history.push(Links.GUIDE_LIST.replace(':songId', response.data.id));
        return;
      } else {
        ons.notification.confirm('Não foi possível solicitar.', {
          title: 'Erro',
          buttonLabels: ["Tentar de novo", "Cancelar"],
        }).then(data => {
          if (0 === data) {
            this.solicitarGuia();
          }
        });
        this.setState({ loading: false });
      }
    } catch (err) {
      ons.notification.confirm('Não foi possível solicitar. ' + ((err && err.data && err.data.message) || ""), {
        title: 'Erro',
        buttonLabels: ["Tentar de novo", "Cancelar"],
      }).then(data => {
        if (0 === data) {
          this.solicitarGuia();
        }
      });

      this.setState({ loading: false });
    }
  }

  solicitarGuiaClick() {
    ons.notification.confirm('Confirma solicitação de Guia?', {
      title: '',
      buttonLabels: ["Sim", "Cancelar"],
    }).then(data => {
      if (0 === data) {
        this.solicitarGuia();
      }
    });
  }

  renderBackButton() {
    return (
      <BackButton
        onClick={() => { this.props.history.push(Links.SONGS); }}
      >Voltar</BackButton>
    );
  }

  renderButtonSave() {
    return (
      <ToolbarButton
        modifier="outline"
        disabled={!this.state.changed || this.state.readonly || this.state.loading || !this.state.data.name || !this.state.data.lyrics ? true : false}
        onClick={() => { this.save(); }}
      >
        Solicitar
      </ToolbarButton>
    );
  }

  renderToolbar() {
    return (
      <>
        <MainToolbar
          title="Solicitar Guia"
          renderLeft={() => this.renderBackButton()}
        />
      </>
    );
  }

  setData(key, value) {
    if (this.state.readonly) {
      return;
    }
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      },
      changed: true,
    });
  }

  handleUserInput(e) {
    if (this.state.readonly) {
      return;
    }
    const { name, value } = e.target;
    this.setData(name, value);
  }

  renderSongEditButton() {
    return (
      <Button
        modifier="quiet"
        onClick={() => this.props.history.push(Links.SONG_EDIT.replace(':songId', this.state.data.id))}
      ><Icon icon="edit" /></Button>
    );
  }

  render() {
    const { name, lyrics, coauthors, audio_file_url } = this.state.data;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <ListTitle>Instruções</ListTitle>
        <List>
          <ListItem>
            <p>
              Confira o <b>Título da Música</b>, a <b>Letra</b>, os <b>Autores</b> e seus <b>E-mails</b> e o <b>Áudio</b>. O importante é termos uma ideia de como você imaginou a melodia para podermos produzir a Guia. Em caso de dúvidas, entre em contato conosco.
            </p>
          </ListItem>
        </List>

        <ListTitle>Informações da Música</ListTitle>
        <List>
          <ListItem>
            <div className="left form-label">
              <label>Título da Música</label>
            </div>
            <div className="center">
              {name}
            </div>
            <div className="right">
              {this.renderSongEditButton()}
            </div>
          </ListItem>
          <ListItem>
            <div className="left form-label">
              <label>Letra</label>
            </div>
            <div className="center">
              <pre>{lyrics}</pre>
            </div>
            <div className="right">
              {this.renderSongEditButton()}
            </div>
          </ListItem>
        </List>

        <ListTitle>Autores</ListTitle>
        <List>
          <ListItem>
            <div className="left form-label">
              <label>Autor 1</label>
            </div>
            <div className="center">
              {this.props.user && this.props.user.data ? this.props.user.data.nickname : "Você"}
            </div>
          </ListItem>

          {coauthors.map((coauthor, index) => (
            <div key={index}>
              {coauthor.nickname && <>
                <ListItem>
                  <div className="left form-label">
                    <label>Autor {(index + 2)}</label>
                  </div>
                  <div className="center">
                    {coauthor.nickname}
                  </div>
                  <div className="right">
                    {this.renderSongEditButton()}
                  </div>
                </ListItem>
                <ListItem>
                  <div className="left form-label">
                    <label>E-mail de {coauthor.nickname}</label>
                  </div>
                  <div className="center">
                    {coauthor.email}
                  </div>
                  <div className="right">
                    {this.renderSongEditButton()}
                  </div>
                </ListItem>
              </>
              }
            </div>
          ))}
        </List>

        <ListTitle>Áudio</ListTitle>
        <List>
          <ListItem>
            <div className="center" style={{ paddingTop: 12, paddingBottom: 12 }}>
              <ReactAudioPlayer src={audio_file_url} controls />
            </div>
            <div className="right">
              {this.renderSongEditButton()}
            </div>
          </ListItem>
        </List>

        <ListTitle>Solicitação de Guia</ListTitle>
        <List>
          <ListItem>
            <div className="center" style={{ paddingTop: 12, paddingBottom: 12 }}>
              <div style={{ marginBottom: 10, width: '100%', textAlign: 'center' }}>
                {(this.props.user.data.current_plan.guia_price !== this.props.user.data.current_plan.guia_price_original)
                  &&
                  <div>
                    Plano <b>{this.props.user.data.current_plan.name}</b> ativo.<br />
                    <small className="text-muted">De <s>{reaisMask(this.props.user.data.current_plan.guia_price_original)}</s> por:</small><br />
                  </div>

                }

                {this.props.user.data.current_plan.guia_price_ic && this.props.user.data.current_plan.guia_price_ic !== '0' && <>
                  <div style={{ marginTop: 8 }} className="text-success"><big>
                    {this.props.user.data.current_plan.guia_price_ic} InoveCoins
                  </big></div>
                  <div style={{ marginTop: 8 }}>ou</div>
                </>}
                <div style={{ marginTop: 8 }} className="text-success"><big>{reaisMask(this.props.user.data.current_plan.guia_price)}</big></div>
              </div>
              <Button
                modifier="large"
                onClick={() => this.solicitarGuiaClick()}
              >
                Solicitar Guia
                <Icon style={{ marginLeft: 8 }} icon="chevron-right" />
              </Button>
            </div>
          </ListItem>
        </List>


        <div style={{ height: 80 }}></div>

        <Modal isOpen={this.state.loading ? true : false}>
          <ProgressCircular indeterminate />
          <div>{this.state.loading}</div>
        </Modal>
      </Page >
    );
    // TODO: incluir campos para Edição Developer
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GuideRequest));
