import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  BackButton,
  BottomToolbar,
  // Icon,
  Input,
  List,
  ListItem,
  ListTitle,
  Modal,
  Page,
  ProgressCircular,
  ToolbarButton
} from 'react-onsenui';
import ons from 'onsenui';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

// import AvatarEditor from 'react-avatar-editor'
import MainToolbar from '../../components/MainToolbar';
import api from '../../services/api';
import Links from '../links';
// import { Container } from './styles';

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.fileInput = React.createRef();
  }

  state = {
    loading: false,
    data: this.props.user.data,
    changed: false,
    file: null,
    file64: null
  };

  async componentDidMount() {
    this.loadProfile();
  }

  async loadProfile() {
    try {
      this.setState({ loading: 'Carregando...' });

      const { data } = await api.get('/user/profile');
      this.setState({
        data,
        changed: false,
        file: null,
        file64: null
      });
      this.props.userActions.setData(data);
    } catch (err) {
      ons.notification
        .confirm('Não foi possível carregar os dados.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Sair']
        })
        .then((data) => {
          if (data === 0) {
            this.loadProfile();
          } else if (data === 1) {
            this.props.history.push(Links.HOME);
          }
        });
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  async saveProfile() {
    if (!this.state.changed) {
      return;
    }
    try {
      const { nickname, description } = this.state.data;

      this.setState({ loading: 'Salvando...' });
      await api.put('/user/profile', {
        nickname,
        description
      });

      if (this.state.file64) {
        const formData = new FormData();
        const { file } = this.state;
        formData.append('img', file, file.name);
        await api.post('/user/profile/img', formData, {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
          },
          timeout: 600000
        });
      }

      this.setState({
        changed: false,
        file: null,
        file64: null
      });
      this.loadProfile();
    } catch (err) {
      ons.notification
        .confirm('Não foi possível salvar.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Cancelar']
        })
        .then((data) => {
          if (data === 0) {
            this.saveProfile();
          }
        });
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  loadImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ file64: e.target.result });
    };
    reader.readAsDataURL(file);
  }

  renderBackButton() {
    return (
      <BackButton
        onClick={() => {
          this.props.history.push(Links.HOME);
        }}
      >
        Voltar
      </BackButton>
    );
  }

  renderButtonSave() {
    return (
      <ToolbarButton
        modifier="outline"
        disabled={!!(!this.state.changed || this.state.loading)}
        onClick={() => {
          this.saveProfile();
        }}
      >
        Salvar
      </ToolbarButton>
    );
  }

  renderToolbar() {
    return (
      <>
        <MainToolbar
          title="Editar Perfil"
          renderLeft={() => this.renderBackButton()}
          renderRight={() => this.renderButtonSave()}
        />
        <BottomToolbar modifier="material aligned" className="right">
          <div>{this.renderButtonSave()}</div>
        </BottomToolbar>
      </>
    );
  }

  render() {
    const { nickname, description, img_url_mini } = this.state.data;
    const { data } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <ListTitle>Instruções</ListTitle>
        <List>
          <ListItem>
            <p>
              É recomendado que mantenha sempre o mesmo pseudônimo. Caso queira
              mudar de pseudônimo, deverá informar primeiro a sua associação!
            </p>
          </ListItem>
        </List>

        <ListTitle>Perfil Público</ListTitle>
        <List>
          <ListItem>
            <div className="left form-label">
              <label>Imagem de Perfil</label>
            </div>
            <div className="center">
              <div>
                <img
                  src={
                    this.state.file64 ||
                    img_url_mini ||
                    this.props.user.data.img_url_mini ||
                    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                  }
                  alt="Imagem de perfil"
                  width={120}
                  height={120}
                  style={{
                    objectFit: 'cover',
                    borderRadius: 4,
                    background: '#e5e5e5'
                  }}
                  onClick={() => {
                    document.getElementById('editarPerfilFileUpload').click();
                  }}
                />
                <br />
                <Button
                  modifier="small outline"
                  onClick={() => {
                    document.getElementById('editarPerfilFileUpload').click();
                  }}
                >
                  Nova Imagem
                </Button>

                <input
                  id="editarPerfilFileUpload"
                  type="file"
                  ref={this.fileInput}
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.setState({
                      file: e.target.files[0],
                      file64: null,
                      changed: true
                    });
                    this.loadImage(e.target.files[0]);
                  }}
                  style={{ width: 0, height: 0 }}
                />
              </div>
            </div>
          </ListItem>

          <ListItem>
            <div className="left form-label">
              <label>Pseudônimo</label>
            </div>
            <div className="center">
              <Input
                style={{ width: '100%' }}
                maxLength={32}
                float
                disabled={false}
                value={nickname}
                onChange={(event) => {
                  this.setState({
                    data: {
                      ...data,
                      nickname: event.target.value
                    },
                    changed: true
                  });
                }}
                placeholder="Pseudônimo"
              />
            </div>
          </ListItem>
          <ListItem>
            <div className="left form-label">
              <label>Biografia</label>
            </div>
            <div className="center">
              <textarea
                style={{ width: '100%' }}
                className="textarea"
                rows={6}
                disabled={false}
                value={description || ''}
                onChange={(event) => {
                  this.setState({
                    data: {
                      ...data,
                      description: event.target.value
                    },
                    changed: true
                  });
                }}
                placeholder="Biografia"
              />
            </div>
          </ListItem>
        </List>

        <Modal isOpen={!!this.state.loading}>
          <ProgressCircular indeterminate />
          <div>{this.state.loading}</div>
        </Modal>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
