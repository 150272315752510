export const cpfMask = value => {
  return (value || "")
    .replace(/\D/g, '')
    .substr(0, 11)
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
}

export const cpfUnmask = value => {
  return (value || "").replace(/\D/g, '')
}

export const phoneMask = value => {
  return (value || "")
    .replace(/\D/g, '')
    .substr(0, 11)
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2')
}
export const phoneUnmask = value => {
  return (value || "").replace(/\D/g, '')
}

export const cepMask = value => {
  return (value || "")
    .replace(/\D/g, '')
    .substr(0, 8)
    .replace(/(\d{5})(\d)/, '$1-$2')
}
export const cepUnmask = value => {
  return (value || "").replace(/\D/g, '')
}


export const dateMask = value => {
  const v = (value || "");
  return v.match(/^\d{4}-\d{2}-\d{2}$/)
    ? v.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')
    : v.replace(/\D/g, '')
      .substr(0, 8)
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
}
export const dateUnmask = value => {
  const v = (value || "");
  return v.match(/^\d{4}\/\d{2}\/\d{2}$/)
    ? v.replace(/(\d{4})\/(\d{2})\/(\d{2})/, '$3-$2-$1')
    : v
}

export const reaisMask = t => 'R$ ' + (parseFloat(t) || 0).toFixed(2).replace('.', ',');
