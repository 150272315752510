import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ons from 'onsenui';

import store from './store';
import Routes from './routes';

import * as serviceWorker from './serviceWorker';

import './index.scss';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

ons.disableAutoStyling();
ons.platform.select('ios');

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// import "./index.css";
serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }
});
