import api from './api';

export const TOKEN_KEY = '@session';
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = async () => {
  const token = getToken();
  localStorage.removeItem(TOKEN_KEY);
  if (token) {
    api.post('/user/logout', { token });
  }
};
