import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Recover from "./pages/Recover";
import RecoverNewPassword from "./pages/RecoverNewPassword";
import App from "./pages/App";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
    }
  />
);


const LoggedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated() ? (
          <Component {...props} />
        ) : (
            <Redirect to={{ pathname: "/app", state: { from: props.location } }} />
          )
      }
    />
  );
};

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <LoggedRoute exact path="/" component={SignIn} />
      <LoggedRoute exact path="/recover" component={Recover} />
      <LoggedRoute path="/recover-new-password" component={RecoverNewPassword} />
      <LoggedRoute path="/signup" component={SignUp} />
      <PrivateRoute path="/app" component={App} />
      <Route path="*" component={() => <div style={{ textAlign: 'center' }}><h1>Página não encontrada<br /><a href="/">Voltar</a></h1></div>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;