import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  // Button,
  BackButton,
  BottomToolbar,
  Icon,
  Input,
  List,
  ListItem,
  ListTitle,
  Modal,
  Page,
  Popover,
  ProgressCircular,
  Select,
  ToolbarButton
} from 'react-onsenui';
import ons from 'onsenui';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

// import AvatarEditor from 'react-avatar-editor'
import MainToolbar from '../../components/MainToolbar';
import api from '../../services/api';
import Links from '../links';
import {
  cpfMask,
  phoneMask,
  cepMask,
  dateMask,
  cpfUnmask,
  phoneUnmask,
  cepUnmask,
  dateUnmask
} from '../../components/masks';
// import { Container } from './styles';

class PersonalData extends Component {
  constructor(props) {
    super(props);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.fileInput = React.createRef();
  }

  state = {
    loading: false,
    data: this.props.user.data,
    changed: false,
    helpIpiOpen: false
  };

  async componentDidMount() {
    this.loadProfile();
  }

  async loadProfile() {
    try {
      this.setState({ loading: 'Carregando...' });

      const { data } = await api.get('/user/profile');
      this.setState({
        data,
        changed: false
      });
      this.props.userActions.setData(data);
    } catch (err) {
      ons.notification
        .confirm('Não foi possível carregar os dados.', {
          title: 'Erro',
          buttonLabels: ['Tentar de novo', 'Sair']
        })
        .then((data) => {
          if (data === 0) {
            this.loadProfile();
          } else if (data === 1) {
            this.props.history.push(Links.HOME);
          }
        });
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  async saveProfile() {
    if (!this.state.changed) {
      return;
    }
    try {
      const fields = {};
      let send = 0;
      const keys = [
        'name',
        'birthdate',
        'naturalidade',
        'estado_civil',
        'rg',
        'rg_orgao',
        'cpf',
        'phone_number',
        'address_postcode',
        'address_line1',
        'address_line2',
        'address_line3',
        'address_neighborhood',
        'address_city',
        'address_state',
        'ipi',
        'associacao',
        'dados_bancarios'
      ];
      keys.forEach((key) => {
        if (this.state.data[key] !== this.props.user.data[key]) {
          fields[key] = this.state.data[key];
          send = 1;
        }
      });

      if (send) {
        this.setState({ loading: 'Salvando...' });
        await api.put('/user/data', fields);

        this.loadProfile();
      } else {
        this.setState({ changed: false });
      }
    } catch (err) {
      ons.notification
        .confirm(
          `Não foi possível salvar. ${err && err.data && err.data.message}`,
          {
            title: 'Erro',
            buttonLabels: ['Tentar de novo', 'Cancelar']
          }
        )
        .then((data) => {
          if (data === 0) {
            this.saveProfile();
          }
        });
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  renderBackButton() {
    return (
      <BackButton
        onClick={() => {
          this.props.history.push(Links.SONGS);
        }}
      >
        Voltar
      </BackButton>
    );
  }

  renderButtonSave() {
    return (
      <ToolbarButton
        modifier="outline"
        disabled={!!(!this.state.changed || this.state.loading)}
        onClick={() => {
          this.saveProfile();
        }}
      >
        Salvar
      </ToolbarButton>
    );
  }

  renderToolbar() {
    return (
      <>
        <MainToolbar
          title="Dados Cadastrais"
          renderLeft={() => this.renderBackButton()}
          renderRight={() => this.renderButtonSave()}
        />
        <BottomToolbar modifier="material aligned" className="right">
          <div>{this.renderButtonSave()}</div>
        </BottomToolbar>
      </>
    );
  }

  setInputData(e) {
    let { name, value } = e.target;
    switch (name) {
      case 'cpf':
        value = cpfUnmask(value);
        break;
      case 'phone_number':
        value = phoneUnmask(value);
        break;
      case 'address_postcode':
        value = cepUnmask(value);
        break;
      case 'birthdate':
        value = dateUnmask(value);
        break;
      default:
    }

    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      },
      changed: true
    });
  }

  render() {
    const { data } = this.state;

    const authorFields = [
      {
        name: 'Autor(a)',
        items: [
          { name: 'name', label: 'Nome Completo', maxLength: 100 },
          {
            name: 'birthdate',
            label: 'Data de Nascimento',
            maxLength: 10,
            placeholder: 'DD/MM/AAAA',
            mask: dateMask
          },
          { name: 'naturalidade', label: 'Naturalidade', maxLength: 50 },
          {
            name: 'estado_civil',
            label: 'Estado Civil',
            select: [
              { key: 'Solteiro', label: 'Solteiro(a)' },
              { key: 'Casado', label: 'Casado(a)' },
              { key: 'Divorciado', label: 'Divorciado(a)' },
              { key: 'Viúvo', label: 'Viúvo(a)' },
              { key: 'Separado', label: 'Separado(a)' }
            ]
          },
          { name: 'rg', label: 'RG', maxLength: 20 },
          { name: 'rg_orgao', label: 'Órgão Expeditor', maxLength: 20 },
          { name: 'cpf', label: 'CPF', maxLength: 14, mask: cpfMask },
          {
            name: 'phone_number',
            label: 'Telefone',
            maxLength: 30,
            placeholder: 'DDD NÚMERO',
            mask: phoneMask
          }
        ]
      },
      {
        name: 'Endereço',
        items: [
          {
            name: 'address_postcode',
            label: 'CEP',
            maxLength: 9,
            mask: cepMask
          },
          { name: 'address_line1', label: 'Logradouro', maxLength: 100 },
          { name: 'address_line2', label: 'Número', maxLength: 10 },
          { name: 'address_line3', label: 'Complemento', maxLength: 50 },
          { name: 'address_neighborhood', label: 'Bairro', maxLength: 50 },
          { name: 'address_city', label: 'Cidade', maxLength: 50 },
          {
            name: 'address_state',
            label: 'Estado',
            select: [
              { key: 'AC', label: 'Acre' },
              { key: 'AL', label: 'Alagoas' },
              { key: 'AM', label: 'Amazonas' },
              { key: 'AP', label: 'Amapá' },
              { key: 'BA', label: 'Bahia' },
              { key: 'CE', label: 'Ceará' },
              { key: 'DF', label: 'Distrito Federal' },
              { key: 'ES', label: 'Espírito Santo' },
              { key: 'GO', label: 'Goiânia' },
              { key: 'MA', label: 'Maranhão' },
              { key: 'MG', label: 'Minas Gerais' },
              { key: 'MS', label: 'Mato Grosso do Sul' },
              { key: 'MT', label: 'Mato Grosso' },
              { key: 'PA', label: 'Pará' },
              { key: 'PE', label: 'Pernambuco' },
              { key: 'PB', label: 'Paraíba' },
              { key: 'PI', label: 'Piauí' },
              { key: 'PR', label: 'Paraná' },
              { key: 'RJ', label: 'Rio de Janeiro' },
              { key: 'RN', label: 'Rio Grande do Norte' },
              { key: 'RO', label: 'Rondônia' },
              { key: 'RR', label: 'Rorâima' },
              { key: 'RS', label: 'Rio Grande do Sul' },
              { key: 'SC', label: 'Santa Catarina' },
              { key: 'SE', label: 'Sergipe' },
              { key: 'SP', label: 'São Paulo' },
              { key: 'TO', label: 'Tocantins' }
            ]
          }
        ]
      },
      {
        name: 'Cadastro de Compositor(a)',
        items: [
          {
            name: 'ipi',
            label: (
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <span>Nº do IPI</span>
                <Icon
                  ref={(iconIpi) => {
                    this.iconIpi = iconIpi;
                  }}
                  style={{ marginLeft: 8, fontSize: 20, opacity: 0.7 }}
                  icon="question-circle"
                  onClick={() => {
                    this.setState(({ helpIpiOpen }) => ({
                      helpIpiTarget: this.iconIpi,
                      helpIpiOpen: true
                    }));
                  }}
                />
              </span>
            ),
            maxLength: 20
          },
          {
            name: 'associacao',
            label: 'Associação',
            maxLength: 50
          },
          {
            name: 'dados_bancarios',
            label: 'Pix ou Conta Bancária',
            maxLength: 100
          }
        ]
      }
    ];

    return (
      <Page renderToolbar={this.renderToolbar}>
        <ListTitle>Instruções</ListTitle>
        <List>
          <ListItem>
            <p>
              Para editar a sua música serão necessários os dados cadastrais
              abaixo.
            </p>
            <ul>
              <li>Será necessário preencher uma única vez;</li>
              <li>Estes dados estarão armazenados na sua conta;</li>
              <li>Você deverá manter estes dados sempre atualizados;</li>
              <li>
                Estas informações são privadas para uso exclusivo nos seus
                contratos, e não serão publicadas no seu perfil público.
              </li>
            </ul>
          </ListItem>
        </List>
        {authorFields.map((group, index) => (
          <div key={index}>
            <ListTitle>{group.name}</ListTitle>
            <List>
              {group.items.map((field, fieldKey) => (
                <ListItem key={fieldKey}>
                  <div className="left form-label">
                    <label>{field.label}</label>
                  </div>
                  <div className="center">
                    {(field.custom && field.custom(field)) ||
                      (field.select ? (
                        <Select
                          name={field.name}
                          style={{ width: '100%' }}
                          value={data[field.name]}
                          onChange={(e) => this.setInputData(e)}
                          placeholder={field.placeholder || field.label}
                        >
                          {field.select.map((i, key) => (
                            <option
                              hidden={i.key === ''}
                              key={key}
                              value={i.key}
                            >
                              {i.label}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Input
                          style={{ width: '100%' }}
                          maxLength={field.maxLength || 100}
                          float
                          disabled={field.disabled || false}
                          name={field.name}
                          value={
                            (field.mask && field.mask(data[field.name])) ||
                            data[field.name]
                          }
                          onChange={(e) => this.setInputData(e)}
                          placeholder={field.placeholder || field.label}
                        />
                      ))}
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        ))}

        <div style={{ height: 80 }} />

        <Modal isOpen={!!this.state.loading}>
          <ProgressCircular indeterminate />
          <div>{this.state.loading}</div>
        </Modal>

        <Popover
          isOpen={this.state.helpIpiOpen}
          onCancel={() => this.setState({ helpIpiOpen: false })}
          getTarget={() => this.state.helpIpiTarget}
        >
          <div style={{ textAlign: 'center', padding: 10, fontSize: '0.8em' }}>
            O número IPI (Informação de partes interessadas) é um número único
            de identificação internacional de compositores e editoras. Este
            registro é gerado após a filiação em alguma sociedade de Direitos
            Autorais e serve para identificar corretamente os detentores de
            direitos autorais em qualquer parte do mundo. Para receber os
            direitos digitais você precisa possuir este registro. Caso você não
            saiba o seu número, entre em contato com a sua Associação de
            Direitos Autorais.
          </div>
        </Popover>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
