import SongList from "../../components/SongList";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as LayoutActions } from '../../store/ducks/layout';

class EditionPaidList extends SongList { };

const mapStateToProps = state => ({
  user: state.user,
  layout: state.layout,
  title: 'Edições Finalizadas',
  emptyListMessage: 'Não há edições finalizadas.',
  endpoint: '/user/edition-contract-list',
  addButton: false,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
  layoutActions: bindActionCreators(LayoutActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditionPaidList);